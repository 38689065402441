import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setSettings} from "../../app/rootReducer";

export const UserSelect = () => {

  const {options, settings} = useAppSelector(state => state.root);
  const {users} = options
  const dispatch = useAppDispatch();

  if (users) {
    return (
     <div className="c-form c-form__select" style={{margin: '0 1rem'}}>
       <i className="icon-arrow-down"/>

       <select value={settings.activeUserId} onChange={(e) => {
         dispatch(setSettings({
         activeUserId: Number(e.target.value)
       }))}}>
         {users.data.map((user) => {
           return (<option value={user.id}>{user.firstName} {user.insertion} {user.lastName}</option>)
         })}

         <option value={0}>Alle medewerkers</option>
       </select>
     </div>
    )
  } else return null

}