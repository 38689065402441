import {BundleInterface} from "../interfaces/BundleInterface";
import {updateBundleStatus} from "../../views/bundleDetailPage/bundleDetailSlice";
import {transportTypeEnum} from "../enums/transportTypeEnum";

export interface VariableBundleDataInterface {
    action: {
        label: string,
        dispatchAction: any
    },
}

export const mapVariableBundleData = (bundle: BundleInterface) => {

    let variableBundleData = {} as VariableBundleDataInterface


    switch (bundle.deliveryType?.code) {
        case('delivery'):

            switch (bundle.status) {

                case('open'):
                    variableBundleData = {
                        action: {
                            label: 'Klaargezet',
                            dispatchAction: updateBundleStatus({
                                id: bundle.id,
                                status: 'prepared'
                            })
                        }
                    }
                    break;
                case('prepared'):
                    variableBundleData = {
                        action: {
                            label: 'Onderweg',
                            dispatchAction: updateBundleStatus({
                                id: bundle.id,
                                status: 'doing'
                            })
                        }
                    }
                    break;
                case('doing'):
                    variableBundleData = {
                        action: {
                            label: 'Afgeleverd',
                            dispatchAction: updateBundleStatus({
                                id: bundle.id,
                                status: 'done'
                            })
                        }
                    }
                    break;

                case('done'):
                    // Items delivered. Bundle is finished
                    break;

            }

            break;
        case('pickup'):


            switch (bundle.status) {

                case('open'):
                    variableBundleData = {
                        action: {
                            label: 'Onderweg',
                            dispatchAction: updateBundleStatus({
                                id: bundle.id,
                                status: 'doing'
                            })
                        }
                    }
                    break;
                case('doing'):

                    if (bundle.transportType.code === transportTypeEnum.LOCATION) {
                        variableBundleData = {
                            action: {
                                label: 'Items opgehaald en in depot',
                                dispatchAction: updateBundleStatus({
                                    id: bundle.id,
                                    status: 'done'
                                })
                            }
                        }
                    }else{
                        variableBundleData = {
                            action: {
                                label: 'Items opgehaald',
                                dispatchAction: updateBundleStatus({
                                    id: bundle.id,
                                    status: 'delivered'
                                })
                            }
                        }
                    }
                    break;
                case('delivered'):
                    variableBundleData = {
                        action: {
                            label: 'Verwerkt',
                            dispatchAction: updateBundleStatus({
                                id: bundle.id,
                                status: 'done'
                            })
                        }
                    }
                    break;

                case('done'):
                    // Items processed. Bundle is finished
                    break;

            }
            break;
    }

    return variableBundleData

}