import {TypeInterface} from "../interfaces/TypeInterface";
import {Link} from "react-router-dom";
import {routeEnum} from "../enums/routeEnum";
import {format, isToday} from 'date-fns'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {changeDate, setSettings} from "../../app/rootReducer";
import {nl} from "date-fns/locale";

interface Props {
  toggleTypeSelect: () => ({}),
  activeType?: TypeInterface,
  switchUser: () => any,
  logout: () => any,
  date: Date
}

export const AppHeader = (props: Props) => {

  const dispatch = useAppDispatch()
  const {user, settings, taskEnvironment} = useAppSelector(state => state.root);

  return (
   <>
     <header className="c-header c-header--tasks">

       <div className="c-header__bundle">
         <Link to={routeEnum.DASHBOARD} className="c-header__logo">
           <img src={taskEnvironment?.logo} alt="logo"/>
         </Link>
         {user?.role?.name === 'manager' &&
         <button className="c-header__switch btn btn--outline" onClick={() => props.toggleTypeSelect()}>
             <span>{props.activeType?.label}</span>
             <i className="icon icon-arrow-down"/>
         </button>
         }
       </div>

       <div className="c-header__date">
         <button onClick={() => dispatch(changeDate('prev'))}><i className="icon-arrow-down"/></button>
         <span>{format(props.date, 'EEEE dd LLLL', {locale: nl})}
           {isToday(props.date) && <small> (vandaag)</small>}
                </span>
         <button onClick={() => dispatch(changeDate('next'))}><i className="icon-arrow-down"/></button>
       </div>

       <div className="c-header__misc">


         {(user?.role?.name === 'manager' || user?.role?.name === 'depot') &&
         <Link to={routeEnum.BULK_SCAN} className="btn btn--small btn--primary" style={{background: taskEnvironment?.info.primaryColorDark}}>
             <span>Items scannen</span>
         </Link>
         }

         <button className="btn btn--small btn--outline" onClick={() => props.switchUser()}>
           <span>Wijzig gebruiker <small>(F12)</small></span>
         </button>

         <button className="btn btn--small btn--outline btn--square" onClick={() => props.logout()}>
           <i className="icon icon-power"/>
         </button>

       </div>
       <div className="c-header__menu-trigger" onClick={() => {
         dispatch(setSettings({
           showMobileMenu: !settings.showMobileMenu
         }))
       }}>
         <span/>
         <span/>
         <span/>
       </div>
     </header>

     {settings.showMobileMenu &&
     <div className="c-menu" style={{display: 'block'}}>
         <div className="o-container o-container--primary">

             <div className="c-menu__misc">

               {(user?.role?.name === 'manager' || user?.role?.name === 'depot') &&
               <Link to={routeEnum.BULK_SCAN} className="btn btn--small btn--primary">
                   <span>Items scannen</span>
               </Link>
               }

                 <button className="btn btn--small btn--outline" onClick={() => {
                   props.switchUser()
                   dispatch(setSettings({
                     showMobileMenu: false
                   }))
                 }}>
                     <span>Wijzig gebruiker <small>(F12)</small></span>
                 </button>

                 <button className="btn btn--small btn--outline btn--square" onClick={() => {
                   props.logout()
                   dispatch(setSettings({
                     showMobileMenu: false
                   }))
                 }}>
                     <i className="icon icon-power"/>
                 </button>

             </div>

         </div>
     </div>
     }

   </>

  )

}