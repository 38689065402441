import {AddressInterface} from "../interfaces/AddressInterface";

export const formatAddressName = (address?: AddressInterface, addressItem?: AddressInterface, addressNumber?: string) => {

    let addressName = address?.name + '. '

    if (address) {

        if (address.street) {
            addressName += address.street
        }

        if (address.street) {
            addressName += ' ' + address.number + ',    '
        }

    }

    if (addressItem) {
        addressName += ' ' + addressItem.name
    }

    if (addressNumber) {
        addressName += ' ' + addressNumber
    }

    return addressName
}