import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootInterface} from "../../app/rootReducer";
import {apiTaskInfoList} from "../../common/api/task/info/apiTaskInfoList";
import {TaskInterface} from "../../common/interfaces/TaskInterface";
import {AxiosResponse} from "axios";
import {apiTaskBundleCreate} from "../../common/api/task/bundle/apiTaskBundleCreate";
import {format, isFuture, isToday} from "date-fns";
import {transportTypeEnum} from "../../common/enums/transportTypeEnum";
import {TaskTotalsInterface} from "../../common/interfaces/TaskTotalsInterface";
import {apiTaskInfoTotals} from "../../common/api/task/info/apiTaskInfoTotals";
import {apiTaskBundleList} from "../../common/api/task/bundle/apiTaskBundleList";
import {BundleInterface} from "../../common/interfaces/BundleInterface";


interface dashboardStateInterface {
    loading: boolean,
    quickBundle: {
        active: boolean,
        tasks: number[],
        submitted: boolean,
        loading: boolean
    },
    activePage?: number,
    limit: number,
    bundles: BundleInterface[]
    tasks?: {
        data?: TaskInterface[]
        pages?: {
            current: number,
            limit: number,
            total: number
        },
    },
    totals: TaskTotalsInterface,
}

const initialState: dashboardStateInterface = {
    loading: false,
    bundles: [],
    limit: 5,
    quickBundle: {
        active: false,
        tasks: [],
        submitted: false,
        loading: false
    },
    totals: {
        customerTasks: {
            code: transportTypeEnum.RENTAL,
            bundles: {
                total: 0,
                open: 0
            },
            deliveryTasks: {
                total: 0,
                open: 0
            },
            pickupTasks: {
                total: 0,
                open: 0
            },
            open: 0,
            total: 0
        },
        locationTasks: {
            code: transportTypeEnum.LOCATION,
            bundles: {
                total: 0,
                open: 0
            },
            deliveryTasks: {
                total: 0,
                open: 0
            },
            pickupTasks: {
                total: 0,
                open: 0
            },
            open: 0,
            total: 0
        },
        lateTasks: {
            total: 0,
            delivery: 0,
            pickup: 0
        }
    }
}


export const loadTasks = createAsyncThunk(
 'dashboard/loadTasks',
 async (_, {getState}) => {
     const {root} = getState() as { root: RootInterface };
     const {settings} = root
     const {dashboard} = getState() as { dashboard: dashboardStateInterface };

     if (settings.type && settings.transportType && settings.deliveryType && settings.employeeType) {

         let archive = true as boolean

         if ((isToday(root.date) && !settings.showArchive) || isFuture(root.date)) {
             archive = false
         }

         const response = await apiTaskInfoList(settings.type, settings.deliveryType, settings.transportType, settings.employeeType, root.date, archive, false, dashboard.limit, dashboard.activePage, root.settings.activeUserId, root.settings.selectedLocationId)

         return response.data
     }
 }
)

export const loadBundles = createAsyncThunk(
 'dashboard/loadBundles',
 async (_, {getState}) => {
     const {root} = getState() as { root: RootInterface };

     if (root.settings.type && root.settings.transportType && root.settings.deliveryType && root.settings.employeeType && (root.settings.showArchive === false || root.settings.showArchive === true)) {
         const response = await apiTaskBundleList(root.settings.type, root.settings.transportType, root.settings.employeeType, root.date, root.settings.showArchive, root.settings.activeUserId, root.settings.selectedLocationId)
         return response.data
     }
 }
)

export const loadTotals = createAsyncThunk(
 'dashboard/loadTotals',
 async (_, {getState}) => {
     const {root} = getState() as { root: RootInterface };
     const {settings} = root

     let archive = true as boolean

     if ((isToday(root.date) && !settings.showArchive) || isFuture(root.date)) {
         archive = false
     }

     if (root.settings.type && root.settings.transportType && root.settings.deliveryType && root.settings.employeeType && root.settings) {
         const response = await apiTaskInfoTotals(root.settings.type, root.settings.transportType, root.settings.employeeType, root.date, archive, root.settings.activeUserId, root.settings.selectedLocationId)
         return response.data
     }
 }
)

export const submitQuickBundle = createAsyncThunk(
 'dashboard/submitQuickBundle',
 async (_, {getState}) => {
     const {dashboard} = getState() as { dashboard: dashboardStateInterface };
     const {root} = getState() as { root: RootInterface };

     const user = JSON.parse(localStorage.getItem('user') + '');

     const response = await apiTaskBundleCreate(user.id, format(root.date, 'yyyy-MM-dd'), dashboard.quickBundle.tasks, root.settings.type + '', root.settings.transportType + '', root.settings.deliveryType + '', root.settings.selectedLocationId)
     return response.data
 }
)


const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        toggleQuickBundle: (state) => {
            state.quickBundle.active = !state.quickBundle.active
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload
        },
        setLimit: (state, action: PayloadAction<number>) => {
            state.limit = action.payload
        },
        setQuickBundleTaskItem: (state, action: PayloadAction<number>) => {
            if (state.quickBundle.tasks.includes(action.payload)) {
                state.quickBundle.tasks = state.quickBundle.tasks.filter((taskId) => taskId !== action.payload)
            } else {
                state.quickBundle.tasks.push(action.payload)
            }
        },
        resetQuickBundle: (state) => {
            state.quickBundle.active = false
            state.quickBundle.tasks = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadTasks.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loadTasks.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {
            if (action.payload) {
                state.tasks = action.payload.items
            }
            state.loading = false
        })
        builder.addCase(loadBundles.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loadBundles.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {

            console.log(action.payload.items)

            if (action.payload && action.payload.items) {
                state.bundles = action.payload.items
            } else {
                state.bundles = []
            }
            state.loading = false
        })

        builder.addCase(loadTotals.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {
            if (action.payload.items) {
                state.totals = action.payload.items.totals
            }
        })

        builder.addCase(submitQuickBundle.pending, (state) => {
            state.quickBundle.submitted = false
            state.quickBundle.loading = true
        })

        builder.addCase(submitQuickBundle.fulfilled, (state) => {
            state.quickBundle.submitted = true
            state.quickBundle.loading = false
            state.quickBundle.active = false
        })


    },
})


export const {toggleQuickBundle, setPage, setQuickBundleTaskItem, setLimit, resetQuickBundle} = dashboardSlice.actions

export const dashboardReducer = dashboardSlice.reducer