export const formatScanCode = (code: string) => {

    let type = 'code' as 'id' | 'code'

    code = code.replace("\\000026", '')

    if (code.includes("t29.nl/i/")) {
        code = code.replace('http://', '');
        code = code.replace('https://', '');
        code = code.replace('www.', '');
        code = code.replace('t29.nl/i/', '');
        type = 'id'
    } else {
        code = code.replace('http://2cr.nl/?1ATX0', '');
        code = code.replace('http://2CR.NL/?1ATX0', '');
        code = code.replace('http://2cr.nl/?1ABB0', '');
        code = code.replace('http://2CR.NL/?1ABB0', '');
        code = code.split('!')[0]
    }

    return {
        type: type,
        code: code
    }

}