import {useParams} from "react-router";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {downloadPdf, load, reset, scan, updateTaskUser, resetTaskUser} from "./TaskDetailSlice";
import 'mapbox-gl/dist/mapbox-gl.css';
import {TaskInfoCard} from "../../common/components/TaskInfoCard";
import {RentalInfoCard} from "./components/RentalInfoCard";
import {PreparedItemsCard} from "./components/PreparedItemsCard";
import {BackButton} from "../../common/components/BackButton";
import {ScannableCategoriesCard} from "./components/ScannableCategoriesCard";
import {SelectUserCard} from "../../common/components/SelectUserCard";
import BarcodeReader from 'react-barcode-reader'
import {Link} from "react-router-dom";
import {routeEnum} from "../../common/enums/routeEnum";
import {Loader} from "../../common/components/Loader";
import {displayTaskActionButton} from "../../common/utils/displayTaskActionButton";
import {canUserAccess} from "../../common/utils/canUserAccess";
import {setNotification} from "../../app/rootReducer";
import {formatRentalCustomerData} from "../../common/utils/formatRentalCustomerData";
import {useNavigate} from "react-router-dom";
import {setPage} from "../dashboardPage/DashboardSlice";
import {taskEnableCategoriesToScan} from "../../common/utils/taskEnableCategoriesToScan";

export const TaskDetailPageContainer = () => {

    let {id} = useParams();
    const dispatch = useAppDispatch()
    const {options, user, taskEnvironment, } = useAppSelector(state => state.root);
    const {taskDetail } = useAppSelector(state => state);
    const {users} = options
    const navigate = useNavigate()
    const {task, categoriesToScan, updateTask, selectedUserId, loading} = useAppSelector(state => state.taskDetail);

    const customerData = formatRentalCustomerData(task?.rental)

    useEffect(() => {
        dispatch(downloadPdf(Number(id)))
        dispatch(load(Number(id)))
    }, [])

    useEffect(() => {

        if (updateTask.updated) {

            navigate(routeEnum.DASHBOARD + '/' + taskEnvironment?.info.clientId)

            dispatch(setNotification({
                label: 'Taak ' + task?.id + ' geupdatet',
                type: 'success'
            }))
            dispatch(reset())
            dispatch(setPage(0))
        }
    }, [updateTask.updated])


    if (task) {

        const displayTaskAction = displayTaskActionButton(task, categoriesToScan)
        const userAccess = canUserAccess(task, user)
        let actionHeaderClass = 'c-subject-header c-subject-header--update'


        if (!displayTaskAction && !task.bundle) {
            actionHeaderClass += ' isDisabled'
        }

        if (!loading) {
            if (userAccess) {
                return (
                 <div>

                     <BarcodeReader onScan={(value: any) => dispatch(scan(value + ''))}/>

                     <div className="c-subheader c-subheader--detail">


                         <div className="c-subheader__task">
                             {!!task.id &&
                             <h1>Taak {task.id}
                                 {!!task.deliveryType?.name &&
                                 <small> ({task.deliveryType?.name})</small>
                                 }
                             </h1>
                             }
                         </div>
                         <div className="c-subheader__misc">
                             {taskDetail.downloadPdf.url && <a className={'btn btn--small btn--outline'} href={taskDetail.downloadPdf.url} target={'_blank'}><span>PDF</span></a>}
                             <BackButton/>
                         </div>
                     </div>

                     <div className="o-container o-container--primary">
                         <div className="c-tasks">
                             {(task.variableTaskData?.action && !task.bundle) &&
                             <header className={actionHeaderClass}>
                                 <h2>Update status naar:</h2>
                                 <div className="c-subject-header__buttons">
                                     <button
                                         onClick={() => dispatch(task.variableTaskData?.action?.dispatchAction)}
                                         className="btn btn--small btn--success">
                                         <span>{task.variableTaskData?.action?.label}</span>
                                     </button>
                                 </div>
                             </header>
                             }

                             {task.bundle &&
                             <header className={actionHeaderClass}>
                                 <div className="c-subject-header__buttons">
                                     <Link
                                         to={'/' + taskEnvironment?.info.clientId + '/' + routeEnum.BUNDLE_DETAIL + '/' + task.bundle.id}
                                         className="btn btn--small btn--success">
                                         <span>Open bundel</span>
                                     </Link>
                                 </div>
                             </header>
                             }

                             <div className="c-task">

                                 <TaskInfoCard
                                  task={task}
                                 />

                                 {task.deliveryType?.code === 'pickup' && task.status.code === 'delivered' &&
                                 <>
                                     {!!task.items?.length && !task.bundle &&
                                     <ScannableCategoriesCard
                                         categories={task.categories}
                                         categoriesToScan={categoriesToScan}
                                         title={"Scan deze items retour"}
                                     />
                                     }
                                 </>}

                                 {taskEnableCategoriesToScan(task) &&
                                 <>

                                     <ScannableCategoriesCard
                                         categories={task.categories}
                                         categoriesToScan={categoriesToScan}
                                         title={'Scan items om deze aan de taak toe te voegen'}
                                     />


                                 </>
                                 }

                                 {(users?.data && !task.bundle && task.status.code !== 'done') &&
                                 <SelectUserCard
                                     users={users.data}
                                     selectedUserId={selectedUserId}
                                     dispatchAction={updateTaskUser}
                                     dispatchActionReset={resetTaskUser}
                                 />
                                 }


                                 {task.items &&
                                 <PreparedItemsCard
                                     items={task.items}
                                 />
                                 }

                                 {customerData && task.rental &&
                                 <RentalInfoCard
                                     rental={task.rental}
                                     phone={customerData.phone}
                                     id={task.rental.id}
                                     email={customerData.email}
                                     name={customerData.name}
                                 />
                                 }

                             </div>
                         </div>
                     </div>
                 </div>
                )
            } else return null
        } else return <Loader/>
    } else return null
}
