import {BundleItem} from "../components/BundleItem";
import {useAppSelector} from "../../../app/hooks";

export const BundleSegment = () => {

    const {bundles} = useAppSelector(state => state.dashboard);
    const deliveryBundles = bundles.filter((bundle) => bundle.deliveryType.code === 'delivery')
    const pickupBundles = bundles.filter((bundle) => bundle.deliveryType.code === 'pickup')
    let deliveryNumber = 0
    let pickupNumber = 0

    if (bundles.length) {
        return (
         <div className={'c-bundles'}>

             {!!deliveryBundles.length && <>
                 <header className={'c-subject-header c-subject-header--small'}>
                     <h1>Bezorging <small>{deliveryBundles.length} bundels</small></h1>
                 </header>
                 <div className={'c-bundles__wrap'}>
                     {deliveryBundles.map((bundle) => {
                         deliveryNumber++
                         return (
                          <BundleItem
                           key={bundle.id}
                           number={deliveryNumber} bundle={bundle}
                          />)
                     })}
                 </div>
             </>}

             {!!pickupBundles.length && <>

                 <header className={'c-subject-header c-subject-header--small'}>
                     <h1>Ophalen <small>{pickupBundles.length} bundels</small></h1>
                 </header>
                 <div className={'c-bundles__wrap'}>
                     {pickupBundles.map((bundle) => {
                         pickupNumber++
                         return (
                          <BundleItem
                           key={bundle.id}
                           number={pickupNumber}
                           bundle={bundle}/>
                         )
                     })}
                 </div>

             </>}

         </div>
        )
    } else return (
     <div className={'c-no-items'} style={{textAlign: 'center', padding: '5rem'}}>
         <p>Geen bundels beschikbaar binnen huidige selectie</p>
     </div>
    )

}