import {useState} from "react";
import {ItemInterface} from "../../../common/interfaces/ItemInterface";

interface Props {
    items: ItemInterface[]
}

export const PreparedItemsCard = (props: Props) => {

    const {items} = props
    let i = 0

    const [isActive, setActive] = useState(true);

    let className = 'c-accordion'

    if (isActive) {
        className += ' accordionActive'
    }

    if (!!items.length) {
        return (
            <div className={className} onClick={() => setActive(!isActive)}>
                <header><h3>Items <small>{items.length} items</small></h3>
                    <i className="icon-arrow-down"/></header>
                <div className="c-accordion__content">
                    <table className="c-table-standard">
                        <tbody>
                        {items.map((item) => {

                            i++;

                            return (
                                <tr>
                                    <td>{i}</td>
                                    {item.category?.alternativeLabel ?
                                     <td>{item.category?.alternativeLabel}</td>
                                     :
                                     <td>{item.category?.name}</td>
                                    }
                                    <td>{item.productCode}</td>
                                    <td>{item.brandModelLabel}</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else return null

}