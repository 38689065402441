import {configureStore} from '@reduxjs/toolkit'
import {loginReducer} from "../views/loginPage/loginSlice";
import rootReducer from "./rootReducer";
import {pinCodeReducer} from "../views/pincodePage/pinCodeSlice";
import {dashboardReducer} from "../views/dashboardPage/DashboardSlice";
import {taskDetailReducer} from "../views/taskDetailPage/TaskDetailSlice";
import {bundleDetailReducer} from "../views/bundleDetailPage/bundleDetailSlice";
import {bundleCreateReducer} from "../views/bundleCreatePage/bundleCreateSlice";
import {bulkScanReducer} from "../views/bulkScanPage/bulkScanSlice";

export const store = configureStore({
    reducer: {
        root: rootReducer,
        login: loginReducer,
        pinCode: pinCodeReducer,
        dashboard: dashboardReducer,
        taskDetail: taskDetailReducer,
        bundleDetail: bundleDetailReducer,
        bundleCreate: bundleCreateReducer,
        bulkScan: bulkScanReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

