import ReactMapboxGl, {Marker} from "react-mapbox-gl";
import {calculateMapBounds} from "../utils/calculateMapBounds";
import {TaskInterface} from "../interfaces/TaskInterface";
import {calculateItemsInTasks} from "../utils/calculateItemsInTasks";

interface Props {
    task: TaskInterface
}

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiYmFydDk5MiIsImEiOiJja2t6YWZvNjA1a2NnMnBxdHR3OWcwamVxIn0.jmqJ1sdkWatA2frBRr3vZg'
});

export const TaskInfoCard = (props: Props) => {


    const {task} = props
    const {variableTaskData} = task

    const bounds = calculateMapBounds(variableTaskData?.locations) as any

    return (
        <div className="c-task-information">
            {(variableTaskData?.locations?.from?.long && variableTaskData.locations?.from?.lat && variableTaskData.locations?.to?.long && variableTaskData.locations?.to?.lat) &&
            <div className="c-task-information__map">
                <Map
                    fitBounds={[bounds[0], bounds[1]]}
                    style="mapbox://styles/bart992/ckkzaiqbv3ex217oaq5q1ymsm"
                    containerStyle={{
                        height: '100%',
                        width: '100%'
                    }}
                >

                    <Marker
                        coordinates={[Number(variableTaskData.locations?.from?.long), Number(variableTaskData.locations?.from?.lat)]}
                        anchor="bottom">
                        <div className="c-tag"><small>A</small></div>
                    </Marker>

                    <Marker
                        coordinates={[Number(variableTaskData.locations?.to?.long), Number(variableTaskData.locations?.to?.lat)]}
                        anchor="bottom">
                        <div className="c-tag c-tag--black"><small>B</small></div>
                    </Marker>

                </Map>

            </div>
            }
            <div className="c-task-information__text">
                <header>
                    <h3>Informatie <small>{task.id}</small></h3>
                    <span><strong>Status:</strong> {task.status.name}</span>
                </header>
                <div className="c-task-information__text__wrap">
                    <div className="c-task-information__text__primary">

                        {variableTaskData?.locations?.from &&
                        <div>
                            <span><div className="c-tag"><small>A</small></div><strong>Van locatie</strong></span>
                            <span>{variableTaskData.locations.from.label}</span>
                        </div>
                        }

                        {variableTaskData?.locations?.to &&
                        <div>
                            <span>
                                <div className="c-tag c-tag--black">
                                <small>B</small></div><strong>Tot locatie</strong>
                            </span>
                            <span>{variableTaskData.locations?.to.label}</span>
                        </div>
                        }

                        <div>
                            <span><strong>Taaknummer</strong></span>
                            <span>{task.id}</span>
                        </div>

                        <div>
                            <span><strong>Aantal items</strong></span>
                            <span>{calculateItemsInTasks([task])}</span>
                        </div>

                        {task.user &&
                        <div>
                            <span><strong>Medewerker</strong></span>
                            <span>{task.user.firstName + ' ' + task.user.insertion + ' ' + task.user.lastName}</span>
                        </div>
                        }

                    </div>

                    <aside>
                        <div>
                            <span><strong>Soort taak</strong></span>
                            <span>{task.type.name}</span>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )

}