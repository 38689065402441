import {Link} from "react-router-dom";
import {TaskInterface} from "../../../common/interfaces/TaskInterface";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {format} from "date-fns";
import {deliveryTypeEnum} from "../../../common/enums/deliveryTypeEnum";
import {calculateItemsInTasks} from "../../../common/utils/calculateItemsInTasks";

interface Props {
  task: TaskInterface,
  action?: {
    function: () => any
  }
  quickBundle: {
    active: boolean,
    included: boolean,
    function: () => any
  }
}

export const TaskItem = (props: Props) => {

  let className = 'c-tasks__single'
  const {task, quickBundle} = props
  const dispatch = useAppDispatch()
  const today = new Date()
  const taskDate = new Date(task.date)
  const {taskEnvironment} = useAppSelector((state) => state.root)
  const itemsInTask = calculateItemsInTasks([task]);
  console.log(itemsInTask)

  if (taskDate < today && task.status.code !== 'done') {
    className += ' lateTask'
  }

  if (quickBundle.included) {
    className += ' isSelected'
  }

  const {variableTaskData} = task

  return (
    <div className={className} onClick={() => {
      if (quickBundle.active && !task.bundle && task.status.code === 'open') {
        quickBundle.function()
      }
    }}>
      <div className="c-task__notification ">
        <i className="icon-info"/>
      </div>
      {(!task.bundle && task.status.code === 'open') &&
      <button className="c-tasks__single__select">
          <div><i className="icon-check"/></div>
          <small>selecteer</small>
      </button>
      }
      <div className="c-tasks__single__info" style={{display: 'table'}}>
        <>

          {variableTaskData && <Link to={variableTaskData?.detailLink}>{task.id}</Link>}

          <span><strong>Status</strong> {task.status.name}</span>

          {task.date &&
          <span>
             <strong>Datum</strong> {format(new Date(task.date), 'dd-MM')}
         </span>
          }

          {!!itemsInTask &&
          <span>
             <strong>Items</strong> {itemsInTask}
         </span>
          }

          {(variableTaskData?.time && variableTaskData?.time.value) &&
          <span>
             <strong>{variableTaskData.time.label}</strong> voor {variableTaskData.time.value}
         </span>
          }


          {task.bundle &&
          <span>
             <strong>Bundel: </strong> #{task.bundle.id}
         </span>
          }

          {task.user &&
          <span>
             <strong>Medewerker</strong> {task.user.firstName + ' ' + task.user.insertion + ' ' + task.user.lastName}
         </span>
          }

          <div>
            <img src="/images/start.svg" alt="" style={{color: taskEnvironment?.info.primaryColorDark}}/>
            <span> {task.variableTaskData?.locations.from.label}</span>
          </div>

          <div>
            <img src="/images/finish.svg" alt="" style={{color: taskEnvironment?.info.primaryColorDark}}/>
            <span> {task.variableTaskData?.locations.to.label}</span>
          </div>

        </>

      </div>
      <div className="c-tasks__single__cta">

        {variableTaskData &&
        <Link to={variableTaskData?.detailLink} className="btn btn--small btn--outline">
            <span>Bekijken</span>
        </Link>
        }

        {(variableTaskData?.action && displayAction(task)) &&
        <button onClick={() => {
          dispatch(variableTaskData?.action?.dispatchAction)
        }} className="btn btn--small btn--success">
            <span>{variableTaskData?.action.label}</span>
        </button>
        }

      </div>
    </div>
  )

}

const displayAction = (task: TaskInterface) => {
  return task.variableTaskData?.action &&
    (task.deliveryType?.code === deliveryTypeEnum.DELIVERY && task.status.code !== 'open') &&
    (task.deliveryType?.code === deliveryTypeEnum.PICKUP && task.status.code !== 'delivered')
}