import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setLimit} from "../../views/dashboardPage/DashboardSlice";

export const LimitSelect = () => {

  const options = [
    5,
    10,
    50,
    100
  ]

  const {limit} = useAppSelector(state => state.dashboard);
  const dispatch = useAppDispatch()

  return (

   <div className="c-form c-form__select">
     <i className="icon-arrow-down"/>

     <select
      value={limit}
      onChange={(e) => {
        dispatch(setLimit(Number(e.target.value)))
      }}
     >
       {options.map((option) => {
         return <option value={option}>Toon {option} taken per pagina</option>
       })}
     </select>
   </div>
  )

}