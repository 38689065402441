import {TypeInterface} from "../interfaces/TypeInterface";
import {TaskTotalsInterface} from "../interfaces/TaskTotalsInterface";
import {transportTypeEnum} from "../enums/transportTypeEnum";


export const getTransportTypeCount = (option: TypeInterface, taskTotals?: TaskTotalsInterface) => {

    let number = 0
    if (taskTotals) {

        if (taskTotals) {
            switch (option.value) {
                case(transportTypeEnum.RENTAL):
                    number = taskTotals.customerTasks.open
                    break;
                case(transportTypeEnum.LOCATION):
                    number = taskTotals.locationTasks.open
                    break;
            }
        }

    }

    return number
}