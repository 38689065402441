import {TypeInterface} from "../../../common/interfaces/TypeInterface";
import {TaskTotalsInterface} from "../../../common/interfaces/TaskTotalsInterface";
import {getTransportTypeCount} from "../../../common/utils/getTransportTypeCount";
import {Link} from "react-router-dom";
import {routeEnum} from "../../../common/enums/routeEnum";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setSettings} from "../../../app/rootReducer";
import {isToday} from "date-fns";
import {resetQuickBundle, setPage} from "../DashboardSlice";
import {deliveryTypeEnum} from "../../../common/enums/deliveryTypeEnum";

interface Props {
    activeTransportType?: TypeInterface,
    options: TypeInterface[]
    setTransportType: (value: string) => any,
    taskTotals?: TaskTotalsInterface
}

export const DashboardHeader = (props: Props) => {

    const {settings, date, taskEnvironment} = useAppSelector(state => state.root);
    const {tasks} = useAppSelector(state => state.dashboard);

    let archiveButtonClass = 'btn btn--small btn--outline'

    if (settings.showArchive) {
        archiveButtonClass += ' btn--secondary'
    }

    const dispatch = useAppDispatch()

    return (
     <div className="c-subheader">
         <div className="c-tabs__navigation c-tabs__navigation--large">
             {props.options.map((option) => {

                 let className = 'c-tabs__navigation__single'
                 if (props.activeTransportType?.value === option.value) {
                     className += ' isActive'
                 }

                 const number = getTransportTypeCount(option, props.taskTotals)

                 return (
                  <div className={className} data-color={taskEnvironment?.info.primaryColorDark}
                       onClick={() => props.setTransportType(option.value)}>
                      <span>{option.label}</span>

                      {!settings.showArchive &&
                      <div>
                          <small>{number}</small>
                      </div>
                      }
                  </div>
                 )
             })}


         </div>
         <div className="c-subheader__misc">

             {isToday(date) &&
             <>
                 {!!settings.showArchive &&
                 <button
                     className={archiveButtonClass}
                     onClick={() => {
                         dispatch(setPage(0))
                         dispatch(resetQuickBundle())
                         dispatch(setSettings({
                             showArchive: false,
                         }))
                     }}
                 >
                     <span>Overzicht</span>
                 </button>
                 }

                 {!settings.showArchive &&
                 <button
                     className={archiveButtonClass}
                     onClick={() => {
                         dispatch(setPage(0))
                         dispatch(resetQuickBundle())
                         dispatch(setSettings({
                             showArchive: true,
                         }))
                     }}
                 >
                     <span>Archief</span>
                 </button>
                 }

             </>
             }

             {(!!tasks?.data?.length && settings.deliveryType !== deliveryTypeEnum.BUNDLE) &&
             <Link to={routeEnum.BUNDLE_CREATE} className="btn btn--small btn--primary"
                   style={{background: taskEnvironment?.info.primaryColorLight}}>
                 <span>Taken bundelen</span>
             </Link>
             }

         </div>
     </div>
    )

}