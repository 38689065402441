import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserInterface} from "../../common/interfaces/UserInterface";
import {apiTaskAuthLogin} from "../../common/api/task/auth/apiTaskAuthLogin";
import {authToggle, RootInterface} from "../../app/rootReducer";

interface loginStateInterface {
    submitStatus?: number,
    submitting: boolean,
    user: UserInterface,
}

const initialState: loginStateInterface = {
    submitting: false,
    user: {
        email: '',
        password: ''
    }
}

export const submit = createAsyncThunk(
 'login/submit',
 async (_, {getState, dispatch}) => {
     const {login} = getState() as { login: loginStateInterface };
     const {root} = getState() as { root: RootInterface };

     if (login.user && login.user.password && login.user.email) {
         const response = await apiTaskAuthLogin(login.user)

         if (response.data.status.code === 200) {
             localStorage.setItem(root.taskEnvironment?.info.clientId + '-client', JSON.stringify(response.data.items.client))
             dispatch(authToggle())
         }

         return response.data.status.code
     }
 }
)

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<UserInterface>) => {
            state.user = {
                ...state.user,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state, action: PayloadAction<number>) => {
            state.submitting = false
            state.submitStatus = action.payload
        })
    },
})

export const {setUserData} = loginSlice.actions
export const loginReducer = loginSlice.reducer