import {deliveryTypeEnum} from "../enums/deliveryTypeEnum";
import {TaskInterface} from "../interfaces/TaskInterface";
import {CategoryToScanInterface} from "../interfaces/CategoryToScanInterface";
import {taskEnableCategoriesToScan} from "./taskEnableCategoriesToScan";

export const displayTaskActionButton = (task: TaskInterface, categoriesToScan?: CategoryToScanInterface[]) => {

    if (taskEnableCategoriesToScan(task) ||
     (task.deliveryType?.code === deliveryTypeEnum.PICKUP && task.status.code === 'delivered')) {

        if (categoriesToScan?.length) {

            const scannedCategories = categoriesToScan?.filter((taskCategory) => taskCategory.scanned)
            return !!scannedCategories?.length

        } else return true


    } else return true

}