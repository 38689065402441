import {useState} from "react";
import {RentalInterface} from "../../../common/interfaces/RentalInterface";
import {format} from "date-fns";

interface Props {
  id: number,
  name?: string,
  email?: string,
  phone?: string,
  rental: RentalInterface
}

export const RentalInfoCard = (props: Props) => {

  const [isActive, setActive] = useState(false);

  let className = 'c-accordion'

  if (isActive) {
    className += ' accordionActive'
  }

  const dateStart = new Date(props.rental.dateRentStart)
  const dateEnd = new Date(props.rental.dateRentEnd)

  return (
   <div className={className}>
     <header onClick={() => {
       setActive(!isActive)
     }}>
       <h3>Boekingsgegevens</h3>
       <i className="icon-arrow-down"/>
     </header>
     <div className="c-accordion__content">
       {props.id &&
       <div>
           <span><strong>Boekingsnummer</strong></span>
           <span>{props.id}</span>
       </div>
       }
       {props.name &&
       <div>
           <span><strong>Naam</strong></span>
           <span>{props.name}</span>
       </div>
       }

       {props.email &&
       <div>
           <span><strong>E-mailadres</strong></span>
           <span>{props.email}</span>
       </div>
       }

       {props.phone &&
       <div>
           <span><strong>Telefoonnummer</strong></span>
           <span>{props.phone}</span>
       </div>
       }

       <div>
         <span><strong>Verhuurperiode</strong></span>
         <span>{format(dateStart, 'dd-MM-yyyy')} t/m {format(dateEnd, 'dd-MM-yyyy')}</span>
       </div>

     </div>
   </div>
  )

}