import {TaskInterface} from "../interfaces/TaskInterface";
import {calculateItemsInCategories} from "./calculateItemsInCategories";

export  const calculateItemsInTasks = (tasks: TaskInterface[]) => {

    let number = 0

    tasks.forEach((task) => {
        number += calculateItemsInCategories(task.categories)
    })

    return number

}