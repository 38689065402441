import {useNavigate} from "react-router-dom";

export const BackButton = () => {

    const navigate = useNavigate()

    return(
        <a className="btn btn--small btn--outline" onClick={() => navigate(-1)}>
            <span>Terug</span>
        </a>
    )

}