import ReactMapboxGl, {Marker} from "react-mapbox-gl";
import {BundleTaskItem} from "../components/BundleTaskItem";
import {TaskInterface} from "../../../common/interfaces/TaskInterface";
import {
    mapTaskLocationsToMarkers
} from "../../../common/utils/mapTaskLocationsToMarkers";
import {calculateMapBounds} from "../../../common/utils/calculateMapBounds";
import {CalculateItemsInTasks} from "../../../common/components/CalculateItemsInTasks";
import {DashboardPagination} from "../../../common/components/Pagination";
import {setPage} from "../bundleCreateSlice";
import {useAppDispatch} from "../../../app/hooks";

interface Props {
    tasks?: {
        data?: TaskInterface[],
        pages?: {
            current: number,
            limit: number,
            total: number
        },
    },
    bundleTasks: TaskInterface[],
}

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiYmFydDk5MiIsImEiOiJja2t6YWZvNjA1a2NnMnBxdHR3OWcwamVxIn0.jmqJ1sdkWatA2frBRr3vZg'
});

export const SelectTasks = (props: Props) => {


    const {tasks, bundleTasks} = props
    const dispatch = useAppDispatch()
    const locationMarkers = mapTaskLocationsToMarkers(tasks?.data)
    const bounds = calculateMapBounds(locationMarkers.all) as any
    const itemCount = CalculateItemsInTasks(tasks?.data)

    if (tasks) {
        return (
            <div className="o-container o-container--primary">
                <div className="c-bundle">
                    <header className="c-subject-header c-subject-header--small c-subject-header--split"><h2>1/2
                        Selecteer taken om deze aan de bundel toe te voegen</h2>
                        <small>{bundleTasks.length} taken / {itemCount} items</small>
                    </header>
                    <div className="c-bundle__info">
                        {!!bounds && bounds[0] && bounds[1] &&
                        <div className="c-bundle__map">
                            <Map
                                fitBounds={bounds}
                                style="mapbox://styles/bart992/ckkzaiqbv3ex217oaq5q1ymsm"
                                containerStyle={{
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                                <>
                                    {locationMarkers.from.map((locationMarker) => {
                                        if (locationMarker.lat && locationMarker.long) {
                                            return (
                                                <Marker
                                                    coordinates={[Number(locationMarker?.long), Number(locationMarker?.lat)]}
                                                    anchor="bottom">
                                                    <div className="c-tag">
                                                        <small>{locationMarker.markerValue}</small>
                                                    </div>
                                                </Marker>
                                            )
                                        }
                                    })}

                                    {locationMarkers.to.map((locationMarker) => {
                                        if (locationMarker.lat && locationMarker.long) {
                                            return (
                                                <Marker
                                                    coordinates={[Number(locationMarker?.long), Number(locationMarker?.lat)]}
                                                    anchor="bottom">
                                                    <div className="c-tag c-tag--black">
                                                        <small>{locationMarker.markerValue}</small>
                                                    </div>
                                                </Marker>
                                            )
                                        }
                                    })}
                                </>
                            </Map>
                        </div>
                        }
                        <div className="c-bundle__wrap selectTasks">
                            {tasks?.data?.map((task) => {
                                return (
                                    <BundleTaskItem
                                        locationMarkers={locationMarkers}
                                        included={!!bundleTasks.find((bundleTask) => bundleTask.id === task.id)}
                                        task={task}
                                        type={'select'}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>

                <DashboardPagination
                    pageClickAction={(value: any) => dispatch(setPage(value))} tasks={tasks}
                />

            </div>
        )
    } else return null

}