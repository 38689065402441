import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BundleInterface} from "../../common/interfaces/BundleInterface";
import {apiTaskBundleDetail} from "../../common/api/task/bundle/apiTaskBundleDetail";
import {apiTaskBundleUpdateStatus} from "../../common/api/task/bundle/apiTaskBundleUpdateStatus";
import {CategoryToScanInterface} from "../../common/interfaces/CategoryToScanInterface";
import {mapCategoriesToScan} from "../../common/utils/mapCategoriesToScan";
import {TaskInterface} from "../../common/interfaces/TaskInterface";
import {formatScanCode} from "../../common/utils/formatScanCode";
import {apiTaskItemDetailByCode} from "../../common/api/task/item/apiTaskItemDetailByCode";
import {ItemInterface} from "../../common/interfaces/ItemInterface";
import {apiTaskInfoAssign} from "../../common/api/task/info/apiTaskInfoAssign";
import {apiTaskItemLinkAll} from "../../common/api/task/item/apiTaskItemLinkAll";
import {deliveryTypeEnum} from "../../common/enums/deliveryTypeEnum";
import {apiTaskItemUnlinkAll} from "../../common/api/task/item/apiTaskItemUnlinkAll";
import {apiTaskItemDetail} from "../../common/api/task/item/apiTaskItemDetail";
import {bundleEnablePrepareItems} from "../../common/utils/bundleEnablePrepareItems";

export interface UpdateBundleInterface {
    id: number,
    status: string
}


interface bundleDetailInterface {
    example?: string,
    loading?: boolean,
    bundle?: BundleInterface,
    selectedUserId?: number,
    updateBundle: {
        updating: boolean,
        updated: boolean
    },
    taskCategoriesToScan: {
        task: TaskInterface,
        categories: CategoryToScanInterface[]
    }[]
}

const initialState: bundleDetailInterface = {
    updateBundle: {
        updating: false,
        updated: false
    },
    taskCategoriesToScan: []
}


export const load = createAsyncThunk(
 'bundle/load',
 async (id: number) => {
     const response = await apiTaskBundleDetail(id)
     return response.data.items
 }
)

export const updateBundleStatus = createAsyncThunk(
 'bundle/updateBundleStatus',
 async (updateBundle: UpdateBundleInterface, {getState}) => {

     const {bundleDetail} = getState() as { bundleDetail: bundleDetailInterface };

     for (const taskCategoryToScan of bundleDetail.taskCategoriesToScan) {
         const itemIds = taskCategoryToScan.categories.flatMap((categoryToScan) => {
             if (categoryToScan.scanned && categoryToScan.item) {
                 return categoryToScan.item.id
             } else return []
         })

         if (bundleDetail.bundle && bundleEnablePrepareItems(bundleDetail.bundle)) {
             await apiTaskItemLinkAll(taskCategoryToScan.task.id, itemIds)
         }

         if (bundleDetail.bundle?.status === 'delivered' && bundleDetail.bundle.deliveryType.code === deliveryTypeEnum.PICKUP) {
             await apiTaskItemUnlinkAll(itemIds)
         }
     }

     return await apiTaskBundleUpdateStatus(updateBundle.id, updateBundle.status)
 }
)


export const updateBundleUser = createAsyncThunk(
 'bundle/updateBundleUser',
 async (userId: number, {getState}) => {

     const {bundleDetail} = getState() as { bundleDetail: bundleDetailInterface };

     if (bundleDetail.selectedUserId) {
         const taskIds = bundleDetail.bundle?.tasks.map((task) => {
             return task.id
         })

         if (taskIds) {
             await apiTaskInfoAssign(userId, taskIds)
         }
     }

     return userId
 })


export const scan = createAsyncThunk(
 'bundle/scan',
 async (code: string) => {

     const scanCodeResult = formatScanCode(code)
     let response

     if (scanCodeResult.type === 'id') {
         response = await apiTaskItemDetail(scanCodeResult.code)
     } else {
         response = await apiTaskItemDetailByCode(scanCodeResult.code)
     }

     if (response.data.items) {
         return response.data.items as ItemInterface
     }

 }
)

const CustomerTaskDetailSlice = createSlice({
    name: 'bundle',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(load.pending, (state) => {
            state.loading = true
        })
        builder.addCase(load.fulfilled, (state, action: PayloadAction<BundleInterface>) => {
            state.loading = false

            if (action.payload) {
                state.taskCategoriesToScan = action.payload.tasks.map((task) => {

                    if (task.user) {
                        state.selectedUserId = task.user.id
                    }

                    return {
                        task: task,
                        categories: []
                    }
                })

                state.taskCategoriesToScan.map((taskCategoryToScan) => {
                    taskCategoryToScan.categories = mapCategoriesToScan(taskCategoryToScan.task, taskCategoryToScan.categories)
                    return taskCategoryToScan
                })

            }

            state.bundle = action.payload
        })
        builder.addCase(updateBundleStatus.pending, (state) => {
            state.updateBundle.updated = false
            state.updateBundle.updating = true
        })
        builder.addCase(updateBundleStatus.fulfilled, (state) => {
            state.updateBundle.updated = true
            state.updateBundle.updating = false
        })
        builder.addCase(updateBundleUser.fulfilled, (state, action: PayloadAction<number>) => {
            state.selectedUserId = action.payload
        })
        builder.addCase(scan.fulfilled, (state, action: PayloadAction<any>) => {
             if (action.payload) {

                 const scannedItem = action.payload as ItemInterface

                 const taskCategoryToScanIndexFoundItemIndex = state.taskCategoriesToScan.findIndex((taskCategoryToScan) => {
                     return taskCategoryToScan.categories.find((taskCategoryToScan) => {
                         return taskCategoryToScan.item?.id === scannedItem.id
                     })
                 })

                 if (taskCategoryToScanIndexFoundItemIndex !== -1) {

                     state.taskCategoriesToScan = state.taskCategoriesToScan.map((taskCategoryToScan, index) => {
                         if (index === taskCategoryToScanIndexFoundItemIndex) {
                             taskCategoryToScan.categories = taskCategoryToScan.categories.map((categoryToScan, index) => {

                                 // Item found in this task
                                 const taskCategoryToScanIndexFoundCategoryIndex = taskCategoryToScan.categories.findIndex((categoryToScan) => {
                                     return scannedItem?.id === categoryToScan.item?.id
                                 })

                                 if (index === taskCategoryToScanIndexFoundCategoryIndex) {
                                     if (categoryToScan.persistent) {
                                         return {
                                             ...categoryToScan,
                                             scanned: !categoryToScan.scanned
                                         }
                                     } else {
                                         return {
                                             category: categoryToScan.category,
                                             scanned: false
                                         }
                                     }
                                 } else {
                                     return {
                                         ...categoryToScan
                                     }
                                 }
                             })

                             return taskCategoryToScan

                         } else return taskCategoryToScan
                     })

                 } else {

                     // Item not found
                     const taskCategoryToScanIndexFoundCategoryIndex = state.taskCategoriesToScan.findIndex((taskCategoryToScan) => {

                         return taskCategoryToScan.categories.find((categoryToScan) => {
                             return categoryToScan.category?.id === scannedItem.availabilityCategory.id && !categoryToScan.item
                         })
                     })


                     state.taskCategoriesToScan = state.taskCategoriesToScan.map((taskCategoryToScan, index) => {
                          if (index === taskCategoryToScanIndexFoundCategoryIndex) {

                              // Look for empty item
                              const categoryToScanIndex = taskCategoryToScan.categories.findIndex((categoryToFind) => {
                                  return categoryToFind.category?.id === scannedItem.availabilityCategory.id && !categoryToFind.item
                              })

                              taskCategoryToScan.categories = taskCategoryToScan.categories.map((categoryToScan, index) => {

                                   if (index === categoryToScanIndex) {

                                       return {
                                           ...categoryToScan,
                                           item: scannedItem,
                                           scanned: true
                                       }
                                   } else return categoryToScan
                               }
                              )

                              return taskCategoryToScan

                          } else
                              return taskCategoryToScan
                      }
                     )
                 }
             }
         }
        )

    }
})

export const {reset} = CustomerTaskDetailSlice.actions
export const bundleDetailReducer = CustomerTaskDetailSlice.reducer
