import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setLocation} from "../../app/rootReducer";

export const BranchSelect = () => {

    const dispatch = useAppDispatch()

    const {options, settings} = useAppSelector(state => state.root);

    console.log(options.locations)

    if (!!options.locations?.length && options.locations.length > 1) {
        return (
            <div className="c-form c-form__select">
                <i className="icon-arrow-down"/>

                <select
                    value={settings.selectedLocationId}
                    onChange={(e) => dispatch(setLocation(Number(e.target.value)))}
                >
                    {options.locations.map((location) => {
                        return <option value={location.id}>{location.name}</option>
                    })}

                    <option value={0}>Alle locaties</option>

                </select>
            </div>
        )
    } else return null

}