import {TaskCategoryInterface} from "../interfaces/TaskCategoryInterface";

export const calculateItemsInCategories = (categories?: TaskCategoryInterface[]) => {

    let count = 0

    categories?.forEach((category) => {
        count += category.quantity
    })

    return count

}