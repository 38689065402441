import {TypeInterface} from "../../../common/interfaces/TypeInterface";
import {BranchSelect} from "../../../common/components/BranchSelect";
import {TaskTotalsInterface} from "../../../common/interfaces/TaskTotalsInterface";
import {getDeliveryTypeCount} from "../../../common/utils/getDeliveryTypeCount";
import {useAppSelector} from "../../../app/hooks";
import {LimitSelect} from "../../../common/components/LimitSelect";
import {UserSelect} from "../../../common/components/UserSelect";

interface Props {
    activeDeliveryType?: TypeInterface,
    activeTransportType?: TypeInterface,
    options: TypeInterface[]
    setDeliveryType: (value: string) => any
    taskTotals?: TaskTotalsInterface
}

export const DashboardTabs = (props: Props) => {

    const {settings} = useAppSelector(state => state.root);

    return (
     <div className="c-tabs">
         <div className="c-tabs__navigation">
             {props.options.map((option) => {


                 let className = 'c-tabs__navigation__single'

                 if (option.value === props.activeDeliveryType?.value) {
                     className += ' isActive'
                 }

                 let number = getDeliveryTypeCount(option, props.activeTransportType, props.taskTotals)

                 return (
                  <div className={className} onClick={() => props.setDeliveryType(option.value)}>
                      <span>{option.label}</span>
                      {!settings.showArchive &&
                      <div>
                          <small>{number}</small>
                      </div>
                      }
                  </div>
                 )
             })}

         </div>

         <div style={{display: 'flex'}}>

             <BranchSelect/>
             <UserSelect/>
             <LimitSelect/>

         </div>
     </div>
    )
}