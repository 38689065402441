import {TaskInterface} from "../../../common/interfaces/TaskInterface";
import {Link} from "react-router-dom";
import {routeEnum} from "../../../common/enums/routeEnum";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {LocationsMarkerInterface} from "../../../common/utils/mapTaskLocationsToMarkers";
import {deliveryTypeEnum} from "../../../common/enums/deliveryTypeEnum";
import {useState} from "react";

interface Props {
    locationMarkers: LocationsMarkerInterface
    task: TaskInterface
}

export const BundleTaskCard = (props: Props) => {

    const {task, locationMarkers} = props
    const [isActiveItems, setActiveItems] = useState(false);
    const {taskEnvironment} = useAppSelector((state) => state.root)
    const dispatch = useAppDispatch()
    const {variableTaskData} = task
    const locationMarkerFrom = locationMarkers.from.find((locationMarker) => locationMarker.label === variableTaskData?.locations.from.label)
    const locationMarkerTo = locationMarkers.to.find((locationMarker) => locationMarker.label === variableTaskData?.locations.to.label)
    let itemsAccordionClassName = 'c-accordion c-accordion--small'

    if (isActiveItems) {
        itemsAccordionClassName += ' accordionActive'
    }
    return (
     <div className="c-bundle__single">
         <div className="c-bundle__single__wrap">
             <div className="c-bundle__single__info">
                 <div className="c-bundle__single__info__primary">
                     <div className="c-bundle__single__info__text">
                         {variableTaskData?.locations.from.label &&
                         <div>
                             <img src="/images/start.svg" alt=""
                                  style={{color: taskEnvironment?.info.primaryColorDark}}/>
                             <span>{variableTaskData.locations.from.label}</span>
                         </div>
                         }
                         {variableTaskData?.locations.to.label &&
                         <div>
                             <img src="/images/finish.svg" alt=""
                                  style={{color: taskEnvironment?.info.primaryColorDark}}/>
                             <span>{variableTaskData.locations.to.label}</span>
                         </div>
                         }
                     </div>
                 </div>
                 <div className="c-bundle__single__info__secondary">
                     <div className="c-bundle__single__info__number">
                         {locationMarkerFrom &&
                         <div className="c-tag"><small>{locationMarkerFrom.markerValue}</small>
                         </div>}
                         {locationMarkerTo &&
                         <div className="c-tag c-tag--black"><small>{locationMarkerTo.markerValue}</small></div>}
                     </div>
                     <div className="c-bundle__single__info__details">
                         <Link
                          to={'/' + taskEnvironment?.info.clientId + '/' + routeEnum.DELIVERY_DETAIL + '/' + task.id}>{task.id}</Link>
                         <span><strong>Status</strong> {task.status.name}</span>
                         {variableTaskData?.time.value &&
                         <span>
                                <strong>{variableTaskData.time.label}</strong> voor {variableTaskData.time.value}
                            </span>
                         }
                     </div>
                 </div>
             </div>
             <div className="c-bundle__single__cta">
                 <Link to={'/' + taskEnvironment?.info.clientId + '/' + routeEnum.DELIVERY_DETAIL + '/' + task.id}
                       className="btn btn--small btn--rounded btn--outline">
                     <span>Bekijken</span>
                 </Link>
                 {(variableTaskData?.action && displayAction(task)) &&
                 <button onClick={() => dispatch(variableTaskData?.action?.dispatchAction)}
                         className="btn btn--small btn--rounded btn--success">
                     <span>{variableTaskData?.action.label}</span>
                 </button>
                 }
             </div>
         </div>
         {!!task.items?.length &&
         <div className={itemsAccordionClassName}>
             <header onClick={() => setActiveItems(!isActiveItems)}>
                 <h3>Items <small>{task.items.length} items</small></h3>
                 <i className="icon-arrow-down"/>
             </header>
             <div className="c-accordion__content">
                 <table className="c-table-standard">
                     {task.items.map((item) => {
                         return (
                          <tr>
                              <td>{item.id}</td>
                              <td>{item.category.alternativeLabel ? item.category.alternativeLabel : item.category.name}</td>
                              <td>{item.productCode}</td>
                              <td>{item.brandModelLabel}</td>
                          </tr>
                         )
                     })}


                 </table>
             </div>
         </div>
         }
     </div>
    )

}

const displayAction = (task: TaskInterface) => {
    return task.variableTaskData?.action &&
     (task.deliveryType?.code === deliveryTypeEnum.DELIVERY && task.status.code !== 'open') &&
     (task.deliveryType?.code === deliveryTypeEnum.PICKUP && task.status.code !== 'delivered')
}