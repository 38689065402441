import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskItemUnlinkAll = async (items: number[], locationId?: number) => {
    return await useAppAxios.post(apiEnum.TASK_ITEM + '/unlink-all', {
        items: items,
        partner_location_id: locationId
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
