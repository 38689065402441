import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiTaskAuthPinCode} from "../../common/api/task/auth/apiTaskAuthPinCode";
import {authToggle, RootInterface} from "../../app/rootReducer";

interface pinCodeStateInterface {
    submitStatus?: number,
    submitting: boolean,
    pinCode?: string,
}

const initialState: pinCodeStateInterface = {
    submitting: false,
}

export const submit = createAsyncThunk(
    'pinCode/submit',
    async (_, {getState, dispatch}) => {
        const {pinCode} = getState() as { pinCode: pinCodeStateInterface };
        const {root} = getState() as { root: RootInterface };

        if (pinCode.pinCode) {

            const response = await apiTaskAuthPinCode(pinCode.pinCode, root.taskEnvironment?.info.clientId)

            if (response.data.status.code === 200) {
                localStorage.setItem('user', JSON.stringify(response.data.items.taskUser))
                dispatch(authToggle())
            }

            return response.data.status.code
        }
    }
)

const pinCodeSlice = createSlice({
    name: 'pinCode',
    initialState,
    reducers: {
        setPinCode: (state, action: PayloadAction<string>) => {
            state.pinCode = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state, action: PayloadAction<number>) => {
            state.submitting = false
            state.submitStatus = action.payload
        })
    },
})

export const {setPinCode} = pinCodeSlice.actions
export const pinCodeReducer = pinCodeSlice.reducer