export const routeEnum = {
    BULK_SCAN: 'bulk-scan',
    BUNDLE_CREATE: 'bundle/create',
    BUNDLE_DETAIL: 'bundle/detail',
    DASHBOARD: '',
    DELIVERY_DETAIL: 'delivery/detail',
    LOGIN: 'login',
    PIN_CODE: 'pincode',
    PREPARE_DELIVERY: 'depot/prepare-delivery',
    PICKUP_DETAIL: 'pickup/detail',
    PICKUP_PROCESS: 'pickup/process',
}
