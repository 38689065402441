import {css} from "@emotion/react";
import {BarLoader} from "react-spinners";
import {useAppSelector} from "../../app/hooks";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 2rem auto 0;
  border-color: red;
`;

export const Loader = () => {

    const {taskEnvironment} = useAppSelector((state) => state.root)

    return (
        <div className={'c-loader'} style={{height: '60vh', display: 'flex', alignItems: 'center'}}>

            <BarLoader  color={taskEnvironment?.info.primaryColorLight} css={override} height={4} width={100}/>

        </div>
    )

}