import ReactMapboxGl, {Marker} from "react-mapbox-gl";
import {BundleTaskItem} from "../components/BundleTaskItem";
import {TaskInterface} from "../../../common/interfaces/TaskInterface";
import {
    mapTaskLocationsToMarkers
} from "../../../common/utils/mapTaskLocationsToMarkers";
import {calculateMapBounds} from "../../../common/utils/calculateMapBounds";

interface Props {
    bundleTasks: TaskInterface[],
}

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiYmFydDk5MiIsImEiOiJja2t6YWZvNjA1a2NnMnBxdHR3OWcwamVxIn0.jmqJ1sdkWatA2frBRr3vZg'
});

export const OrderTasks = (props: Props) => {


    const {bundleTasks} = props
    let taskNumber = 0

    if (bundleTasks) {

        console.log(bundleTasks)

        const locationMarkers = mapTaskLocationsToMarkers(bundleTasks)
        const bounds = calculateMapBounds(locationMarkers.all) as any


        return (
            <div className="o-container o-container--primary">
                <div className="c-bundle">
                    <header className="c-subject-header c-subject-header--small c-subject-header--split">
                        <h2>2/2 Bepaal volgorde van taken</h2>
                    </header>
                    <div className="c-bundle__info">
                        {!!bounds && bounds[0] && bounds[1] &&
                        <div className="c-bundle__map">
                            <Map
                                fitBounds={bounds}
                                style="mapbox://styles/bart992/ckkzaiqbv3ex217oaq5q1ymsm"
                                containerStyle={{
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                                <>
                                    {locationMarkers.from.map((locationMarker) => {
                                        if (locationMarker.lat && locationMarker.long) {
                                            return (
                                                <Marker
                                                    coordinates={[Number(locationMarker?.long), Number(locationMarker?.lat)]}
                                                    anchor="bottom">
                                                    <div className="c-tag">
                                                        <small>{locationMarker.markerValue}</small>
                                                    </div>
                                                </Marker>
                                            )
                                        }
                                    })}

                                    {locationMarkers.to.map((locationMarker) => {

                                        if (locationMarker.lat && locationMarker.long) {
                                            return (
                                                <Marker
                                                    coordinates={[Number(locationMarker?.long), Number(locationMarker?.lat)]}
                                                    anchor="bottom">
                                                    <div className="c-tag c-tag--black">
                                                        <small>{locationMarker.markerValue}</small>
                                                    </div>
                                                </Marker>
                                            )
                                        }
                                    })}
                                </>
                            </Map>
                        </div>
                        }
                        <div className="c-bundle__wrap selectTasks">
                            {bundleTasks?.slice().sort(((a, b) => Number(a.custom?.order) - Number(b.custom?.order))).map((task) => {
                                taskNumber++

                                return (
                                    <BundleTaskItem
                                        number={taskNumber}
                                        taskLength={bundleTasks.length}
                                        locationMarkers={locationMarkers}
                                        included={!!bundleTasks.find((bundleTask) => bundleTask.id === task.id)}
                                        task={task}
                                        type={'order'}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>
        )
    } else return null

}