import {UserInterface} from "../interfaces/UserInterface";
import {useAppDispatch} from "../../app/hooks";

interface Props {
  users: UserInterface[],
  selectedUserId?: number,
  dispatchAction?: any,
  dispatchActionReset?: any
}

export const SelectUserCard = (props: Props) => {

  const {users, selectedUserId} = props
  const dispatch = useAppDispatch()

  return (
    <div className="c-task-appoint" >
      <header style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}><h3>Taak toewijzen</h3> {!!selectedUserId && <button onClick={() => dispatch(props.dispatchActionReset())}>Reset</button>}
      </header>
      <div className="c-task-appoint__content">


        {users.map((user) => {

          let className = 'c-task-appoint__single'

          if (user.id === selectedUserId) {
            className += ' isAppointed'
          }

          return (
            <button className={className} onClick={() => {
              dispatch(props.dispatchAction(user.id))
            }}>

              <div className="c-tasks__single__select">
                <div><i className="icon-check"/></div>
                <small>selecteer</small>
              </div>

              <span>{user.firstName} {user.insertion} {user.lastName}</span>

            </button>
          )

        })}


      </div>
    </div>
  )

}