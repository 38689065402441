import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setPinCode, submit} from "./pinCodeSlice";
import {Notification} from "../../common/components/Notification";

export const PinCodePageContainer = () => {

    const {taskEnvironment} = useAppSelector(state => state.root);
    const {pinCode, submitStatus, submitting} = useAppSelector(state => state.pinCode);
    const dispatch = useAppDispatch()

    return (
     <div className="c-auth">
         <div className="c-auth__intro">
             <img src={taskEnvironment?.logo} alt="" className="c-auth__logo"/>
             <h1>{taskEnvironment?.info.introTitle}</h1>
             <span>{taskEnvironment?.info.introSubtitle}</span>
         </div>
         <form
          onSubmit={(e) => {
              e.preventDefault()
              dispatch(submit()).then(() => {
                  console.log(submitStatus)
              })
          }}
          className="c-auth__form c-form">
             <small>Toegangscode invoeren</small>
             <div className="c-form__row">
                 <div className="c-form__group">
                     <label htmlFor="password">Toegangscode</label>
                     <div className="c-form__pin">
                         <i className="icon-lock"/>
                         <input
                          value={pinCode}
                          onChange={(e) => {
                              dispatch(setPinCode(e.target.value + ''))
                          }}
                          required
                          type="password"
                          id="password"/>
                     </div>
                 </div>
             </div>
             <div className="c-form__row">
                 <div className="c-form__group">
                     <button
                      style={{backgroundColor: taskEnvironment?.info.primaryColorLight}}
                      disabled={submitting}
                      className="btn btn--primary">
                         <span>Inloggen</span>
                     </button>
                 </div>
             </div>

             {submitStatus &&
             <>
                 {(submitStatus === 404 || submitStatus === 401) &&
                 <Notification
                     type={'error'}
                     text={'Pincode niet herkend.'}
                 />
                 }

                 {submitStatus === 500 &&
                 <Notification
                     type={'error'}
                     text={'Er is iets misgegaan'}
                 />}
             </>
             }

         </form>
     </div>

    )
}