import {UserInterface} from "../interfaces/UserInterface";
import {deliveryTypeEnum} from "../enums/deliveryTypeEnum";
import {TaskInterface} from "../interfaces/TaskInterface";

export const canUserAccess = (task: TaskInterface, user?: UserInterface,) => {

    switch (user?.role?.name) {

        case('depot'):
            return (task.deliveryType?.code === deliveryTypeEnum.DELIVERY && task.status.code === 'open') || (task.deliveryType?.code === deliveryTypeEnum.PICKUP && task.status.code === 'delivered')

        case('transport'):
            return (task.deliveryType?.code === deliveryTypeEnum.DELIVERY && task.status.code !== 'open') || (task.deliveryType?.code === deliveryTypeEnum.PICKUP && task.status.code !== 'delivered')

        case('manager'):
            return true

    }

    return false

}