import {TaskInterface} from "../interfaces/TaskInterface";

export const CalculateItemsInTasks = (tasks?: TaskInterface[]) => {

    let itemCount = 0


    tasks?.forEach((task) => {
        if(task.items){
            itemCount += task.items?.length
        }
    })

    return itemCount

}