import {TaskInterface} from "../../../common/interfaces/TaskInterface";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeOrder, setBundleTask} from "../bundleCreateSlice";
import {LocationsMarkerInterface} from "../../../common/utils/mapTaskLocationsToMarkers";
import {routeEnum} from "../../../common/enums/routeEnum";

interface Props {
  task: TaskInterface,
  included: boolean,
  locationMarkers: LocationsMarkerInterface,
  type: 'select' | 'order',
  number?: number,
  taskLength?: number
}

export const BundleTaskItem = (props: Props) => {

  const dispatch = useAppDispatch()
  const {task, included, type, number} = props
  let itemNumber = 0;
  let className = 'c-bundle__single'

  if (included) {
    className += ' isSelected'
  }
  const {taskEnvironment} = useAppSelector((state) => state.root)
  const locationMarkerFrom = props.locationMarkers.from.find((locationMarker) => locationMarker.label === task.variableTaskData?.locations.from.label)
  const locationMarkerTo = props.locationMarkers.to.find((locationMarker) => locationMarker.label === task.variableTaskData?.locations.to.label)


  return (
   <div className={className}>
     <div className="c-bundle__single__wrap">
       <div className="c-bundle__single__info">
         <div className="c-bundle__single__info__primary">

           {(type === 'order' && number) &&
           <div className="c-plus-minus">
               <button onClick={() => {
                 dispatch(changeOrder({id: task.id, order: -1}))
               }} disabled={number === 1}><i className="icon-arrow-down"/></button>

               <small>{number}</small>
               <button
                   onClick={() => {
                     dispatch(changeOrder({id: task.id, order: 1}))
                   }}
                   disabled={(number === props.taskLength)}>
                   <i className="icon-arrow-down"/>
               </button>
           </div>
           }

           {type === 'select' &&
           <button
               onClick={() => {
                 dispatch(setBundleTask(props.task))
               }}
               className="c-tasks__single__select">
               <div><i className="icon-check"/></div>
               <small>selecteer</small>
           </button>
           }
           <div className="c-bundle__single__info__text">
             {task.variableTaskData?.locations.from.label &&
             <div>
                 <img src="/images/start.svg" alt="" style={{color: taskEnvironment?.info.primaryColorDark}}/>
                 <span>{task.variableTaskData?.locations.from.label}</span>
             </div>
             }
             {task.variableTaskData?.locations.to.label &&
             <div>
                 <img src="/images/finish.svg" alt="" style={{color: taskEnvironment?.info.primaryColorDark}}/>
                 <span>{task.variableTaskData?.locations.to.label}</span>
             </div>
             }
           </div>
         </div>
         <div className="c-bundle__single__info__secondary">
           <div className="c-bundle__single__info__number">
             {locationMarkerFrom &&
             <div className="c-tag">
                 <small>{locationMarkerFrom.markerValue}</small>
             </div>
             }
             {locationMarkerTo &&
             <div className="c-tag c-tag--black">
                 <small>{locationMarkerTo.markerValue}</small>
             </div>
             }
           </div>


           <div className="c-bundle__single__info__details">
             <Link to={routeEnum.DELIVERY_DETAIL + '/' + task.id}>{task.id}</Link>
             <span><strong>Status</strong> {task.status.name}</span>

             {task.variableTaskData?.time.value &&
             <span>
                            <strong>{task.variableTaskData.time.label}</strong> voor {task.variableTaskData.time.value}
                            </span>
             }

           </div>
         </div>
       </div>
     </div>

     {task.items &&
     <div className="c-accordion c-accordion--small">
         <header>
             <h3>Items <small>{task.items.length}</small></h3>
             <i className="icon-arrow-down"/>
         </header>
         <div className="c-accordion__content">
             <table className="c-table-standard">
                 <tbody>
                 {task.items.map((item) => {
                   itemNumber++

                   return (
                    <tr>
                      <td>{itemNumber}</td>
                      <td>{item.category}</td>
                      <td>{item.productCode}</td>
                      <td>{item.brandModelLabel}</td>
                    </tr>
                   )
                 })}
                 </tbody>
             </table>
         </div>
     </div>
     }
   </div>
  )
}