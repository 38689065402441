import {useAppSelector} from "../../app/hooks";

export const EnvironmentNotFoundPageContainer = () => {

    const {taskEnvironment} = useAppSelector(state => state.root);

    return (
     <div className="c-auth">
         <div className="c-auth__intro" style={{textAlign: 'center'}}>
             <img src={taskEnvironment?.logo} alt="" className="c-auth__logo"/>
             <p style={{textAlign: 'center'}}>Geen takenomgeving gevonden</p>
         </div>
     </div>
    )

}