import {TypeInterface} from "../interfaces/TypeInterface";

interface Props {
    toggleTypeSelect: () => any,
    activeType?: TypeInterface,
    options: TypeInterface[],
    setActiveType: (value: string) => {}
}

export const TypeSelectOverlay = (props: Props) => {

    return (
        <div className="c-overlay" style={{display: 'flex'}}>
            <div className="c-overlay__bg"/>

            <button className="c-overlay__close" onClick={() => props.toggleTypeSelect()}>
                <i className="icon-cross"/>
            </button>

            <div className="c-overlay__menu">
                {props.options.map((option) => {

                    let className = ''

                    if (option.value === props.activeType?.value) {
                        className += 'isActive'
                    }
                    return (<p onClick={() => props.setActiveType(option.value)} className={className}>{option.label}</p>)
                })}
            </div>
        </div>
    )

}