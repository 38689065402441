import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setUserData, submit} from "./loginSlice";
import {Notification} from "../../common/components/Notification";

export const LoginPageContainer = () => {

    const {taskEnvironment} = useAppSelector(state => state.root);

    const {user, submitStatus, submitting} = useAppSelector(state => state.login);
    const dispatch = useAppDispatch()

    return (
     <div className="c-auth">
         <div className="c-auth__intro">
             <img src={taskEnvironment?.logo} className="c-auth__logo"/>
             <h1>{taskEnvironment?.info.introTitle}</h1>
             <span>{taskEnvironment?.info.introSubtitle}</span>
         </div>
         <form className="c-auth__form c-form" onSubmit={(e) => {
             e.preventDefault()
             dispatch(submit())
         }}>
             <small>Inloggen</small>
             <div className="c-form__row">
                 <div className="c-form__group">
                     <label htmlFor="email">E-mailadres</label>
                     <input
                      value={user.email}
                      onChange={(e) => dispatch(setUserData({
                          email: e.target.value + ''
                      }))}
                      required={true}
                      type="email"
                      id="email"
                     />
                 </div>
             </div>
             <div className="c-form__row">
                 <div className="c-form__group">
                     <label htmlFor="password">Wachtwoord</label>
                     <input
                      value={user.password}
                      onChange={(e) => dispatch(setUserData({
                          password: e.target.value + ''
                      }))}
                      type="password" id="password"/>
                 </div>
             </div>
             <div className="c-form__row">
                 <div className="c-form__group">
                     <button
                      style={{backgroundColor: taskEnvironment?.info.primaryColorLight}}
                      disabled={submitting} type={'submit'} className="btn btn--primary"><span>Inloggen</span>
                     </button>
                 </div>
             </div>

             {submitStatus &&
             <>
                 {(submitStatus === 404 || submitStatus === 401) &&
                 <Notification
                     type={'error'}
                     text={'Combinatie van e-mailadres en wachtwoord niet herkent. ' +
                     'Probeer het opnieuw.'}
                 />
                 }

                 {submitStatus === 500 &&
                 <Notification
                     type={'error'}
                     text={'Er is iets misgegaan'}
                 />}
             </>
             }

         </form>
     </div>
    )
}