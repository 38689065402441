export const calculateMapBounds = (locations: any) => {

    if (locations) {

        let bounds = [] as any[]
        const spacing = 0.1

        Object.values(locations).forEach((location: any) => {
            if (location.long && location.lat) {
                bounds.push({lat: parseFloat(location.long), long: parseFloat(location.lat)})
            }
        })

        let lat = bounds.map(function (p) {
            return p.lat
        });
        let long = bounds.map(function (p) {
            return p.long
        });

        if (lat.length && long.length) {

            let min_coords = [
                Math.min.apply(null, lat) - spacing,
                Math.min.apply(null, long) - spacing
            ]

            let max_coords = [
                Math.max.apply(null, lat) + spacing,
                Math.max.apply(null, long) + spacing
            ]

            return [min_coords, max_coords];

        } else return null

    } else return null
}

