import {deliveryTypeEnum} from "../enums/deliveryTypeEnum";
import {transportTypeEnum} from "../enums/transportTypeEnum";
import {TypeInterface} from "../interfaces/TypeInterface";
import {TaskTotalsInterface} from "../interfaces/TaskTotalsInterface";


export const getDeliveryTypeCount = (option: TypeInterface, activeTransportType: any, taskTotals?: TaskTotalsInterface) => {

    let number = 0
    if (taskTotals) {


        switch (option.value) {
            case(deliveryTypeEnum.DELIVERY):

                if (activeTransportType?.value === transportTypeEnum.RENTAL) {
                    number = taskTotals.customerTasks.deliveryTasks.open
                }

                if (activeTransportType?.value === transportTypeEnum.LOCATION) {
                    number = taskTotals.locationTasks.deliveryTasks.open
                }

                break;

            case(deliveryTypeEnum.BUNDLE):
                if (activeTransportType?.value === transportTypeEnum.RENTAL) {
                    number = taskTotals.customerTasks.bundles.open
                }
                if (activeTransportType?.value === transportTypeEnum.LOCATION) {
                    number = taskTotals.locationTasks.bundles.open
                }
                break;
            case(deliveryTypeEnum.PICKUP):
                if (activeTransportType?.value === transportTypeEnum.RENTAL) {
                    number = taskTotals.customerTasks.pickupTasks.open
                }
                if (activeTransportType?.value === transportTypeEnum.LOCATION) {
                    number = taskTotals.locationTasks.pickupTasks.open
                }
                break;


        }
    }

    return number
}