import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskInfoDownloadPdf = async (id: number) => {
    return await useAppAxios.post(apiEnum.TASK_INFO + '/download-pdf', {}, {
        params: {
            id: id,
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
