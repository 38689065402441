import {setQuickBundleTaskItem, submitQuickBundle, toggleQuickBundle} from "../DashboardSlice";
import {Notification} from "../../../common/components/Notification";
import {TaskItem} from "../components/TaskItem";
import {updateTaskStatus} from "../../taskDetailPage/TaskDetailSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {isToday, isFuture} from "date-fns";

export const TaskSegment = () => {

    let tasksContainerClass = 'c-tasks'
    const {settings, date} = useAppSelector(state => state.root);

    const {quickBundle, tasks, totals} = useAppSelector(state => state.dashboard);
    const dispatch = useAppDispatch()

    if (quickBundle.active) {
        tasksContainerClass += ' selectTasks'
    }


    if (!!tasks?.data?.length) {

        return (
         <div className={tasksContainerClass}>


             {!quickBundle.active ?

              <>
                  {(!settings.showArchive && (isToday(date) || isFuture(date))) &&
                  <button className="c-tasks__quick-bundle btn btn--text isActive"
                          onClick={() => dispatch(toggleQuickBundle())}>
                      <span>Snel bundelen</span>
                  </button>
                  }
              </>
              :
              <header className="c-subject-header c-subject-header--split">

                  <h1>Snelle bundel maken <small>{quickBundle.tasks.length} taken</small></h1>

                  <div className="c-subject-header__buttons">

                      <button className="btn btn--small btn--text"
                              onClick={() => dispatch(toggleQuickBundle())}>
                          <span>Annuleren</span>
                      </button>

                      {!!quickBundle.tasks.length &&
                      <button className="btn btn--small btn--primary"
                              onClick={() => dispatch(submitQuickBundle())}>
                          <span>Bundel aanmaken</span>
                      </button>
                      }

                  </div>
              </header>
             }

             {!settings.showArchive && ((!!Number(totals.lateTasks.delivery) && settings.deliveryType === 'delivery') || (!!Number(totals.lateTasks.pickup) && settings.deliveryType === 'pickup') && !settings.showArchive) &&
             <Notification
                 type={'error'}
                 text={'Let op, je hebt nog een openstaande taak staan. Werk deze zo spoedig mogelijk af'}
             />
             }

             {tasks &&
             <div className={'c-tasks__wrap'} style={{width: '100%'}}>
                 {tasks.data?.map((task) => {

                     return (
                      <TaskItem
                       key={task.id}
                       task={task}
                       quickBundle={{
                           active: quickBundle.active,
                           included: quickBundle.tasks.includes(task.id),
                           function: () => dispatch(setQuickBundleTaskItem(task.id))
                       }}
                       action={
                           {
                               function: () => dispatch(updateTaskStatus({
                                   id: task.id,
                                   status: 'delivered'
                               }))
                           }}
                      />
                     )
                 })}
             </div>
             }


         </div>
        )

    } else return (
     <div className={'c-no-items'} style={{textAlign: 'center', padding: '5rem'}}>
         <p>Geen taken beschikbaar binnen huidige selectie</p>
     </div>
    )

}