export const apiEnum = {
    AUTH: process.env.REACT_APP_BASE_URL + '/api/auth',
    TASK_AUTH: process.env.REACT_APP_BASE_URL + '/api/task/auth',
    TASK_INFO: process.env.REACT_APP_BASE_URL + '/api/task/info',
    TASK_BUNDLE: process.env.REACT_APP_BASE_URL + '/api/task/bundle',
    TASK_ENVIRONMENT: process.env.REACT_APP_BASE_URL + '/api/task/environment',
    TASK_ITEM: process.env.REACT_APP_BASE_URL + '/api/task/item',
    TASK_USER: process.env.REACT_APP_BASE_URL + '/api/task/user'
}

