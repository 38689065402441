interface Props {
    goBack: () => any
}

export const BulkScanHeader = (props:Props) => {
    return(
        <div className="c-subheader c-subheader--detail">
            <div className="c-subheader__task">
                <h1>Bulk scan</h1>
            </div>
            <div className="c-subheader__misc">
                <button onClick={() => props.goBack()} className="btn btn--small btn--outline">
                    <span>Terug</span>
                </button>
            </div>
        </div>
    )
}