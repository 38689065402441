import {TaskInterface} from "../../../common/interfaces/TaskInterface";
import {calculateItemsInTasks} from "../../../common/utils/calculateItemsInTasks";
import {mapTasksCategoryQuantities} from "../../../common/utils/mapTasksCategoryQuantities";
import {TaskCategoryToScanInterface} from "../../../common/interfaces/TaskCategoryToScanInterface";
import {DevScan} from "../../../common/components/DevScan";
import {useAppDispatch} from "../../../app/hooks";
import {scan} from "../bundleDetailSlice";

interface Props {
    tasks: TaskInterface[],
    taskCategoriesToScan: TaskCategoryToScanInterface[]
}

export const BundlePrepareItemsCard = (props: Props) => {

    const {tasks, taskCategoriesToScan} = props
    const tasksCategoriesQuantities = mapTasksCategoryQuantities(tasks)
    const dispatch = useAppDispatch()

    return (
     <div className="c-task-scan">
         <header className="c-task-scan__header">
             <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                 <h2>Scan items om deze aan de taak toe te voegen <small>{calculateItemsInTasks(tasks)} items</small>
                 </h2>
                 <DevScan onScan={(value: any) => dispatch(scan(value + ''))}/>
             </div>

             <ul className="u-reset-ul">
                 {tasksCategoriesQuantities.map((taskCategoryQuantity) => {
                     return <li><span>{taskCategoryQuantity.quantity}x {taskCategoryQuantity.label}</span></li>
                 })}

             </ul>
         </header>
         <div className="c-task-scan__content">

             {taskCategoriesToScan.map((taskCategory) => {

                 return (
                  <div className="c-task-scan__content__bundle">
                      <header><span>Taak {taskCategory.task.id}</span></header>
                      <table className="c-table-standard c-table-standard--small">
                          <tbody>

                          <tr>
                              <th/>
                              <th>Categorie</th>
                              <th>Merk en model</th>
                              <th>Productcode</th>
                          </tr>

                          {taskCategory.categories.map((categoryToScan) => {

                              let className = 'noScan'
                              let scanned = false

                              if (categoryToScan.scanned) {
                                  className = ''
                                  scanned = true
                              }

                              return (
                               <tr className={className}>
                                   <td>{scanned && <i className="icon-check-circle"/>}</td>
                                   <td>{categoryToScan.category?.name}</td>
                                   <td>{categoryToScan.item?.brandModelLabel}</td>
                                   <td>{categoryToScan.item?.productCode}</td>
                               </tr>
                              )
                          })}


                          </tbody>
                      </table>

                  </div>
                 )


             })}


         </div>
     </div>
    )

}