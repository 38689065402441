import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {format, isToday} from "date-fns";

export const apiTaskInfoTotals = async (type: string, transportType: string, employeeType: string, date: Date, archive: boolean, userId?: number, locationId?: number) => {

    const data = {} as any

    if(!!userId){
        data.taskUserId = userId
    }

    if (locationId) {
        data.partnerLocationId = locationId
    }

    if (!isToday(date)) {
        data.date = format(date, 'yyyy-MM-dd')
    }

    data.archive = archive

    return await useAppAxios.post(apiEnum.TASK_INFO + '/totals', data, {
        params: {
            type: type,
            transportType: transportType,
            employeeType: employeeType
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
