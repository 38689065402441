import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiAuthConnect} from "../common/api/auth/apiAuthConnect";
import {PayloadAction} from "@reduxjs/toolkit";
import {TypeInterface} from "../common/interfaces/TypeInterface";
import {addDays, subDays} from "date-fns";
import {typeEnum} from "../common/enums/typeEnum";
import {transportTypeEnum} from "../common/enums/transportTypeEnum";
import {deliveryTypeEnum} from "../common/enums/deliveryTypeEnum";
import {UserInterface} from "../common/interfaces/UserInterface";
import {apiTaskUserList} from "../common/api/task/user/apiTaskUserList";
import {AxiosResponse} from "axios";
import {apiTaskUserLocations} from "../common/api/task/user/apiTaskUserLocations";
import {LocationInterface} from "../common/interfaces/LocationInterface";
import {apiAuthMe} from "../common/api/auth/apiAuthMe";
import {TaskEnvironmentInterface} from "../common/interfaces/TaskEnvironmentInterface";
import {apiTaskEnvironmentDetialByUrl} from "../common/api/task/environment/apiTaskEnvironmentDetialByUrl";

export const connect = createAsyncThunk(
 'root/connect',
 async (_) => {
   const response = await apiAuthConnect()
   localStorage.setItem('accessToken', response.data.items.accessToken.access_token)
   localStorage.setItem("refreshToken", response.data.items.accessToken.refresh_token);
 }
)

export const getEnvironment = createAsyncThunk(
 'root/getEnvironment',
 async (environmentId: number) => {
   const response = await apiTaskEnvironmentDetialByUrl(environmentId)
   return response.data.items?.taskEnvironment as TaskEnvironmentInterface
 }
)

export const getUserList = createAsyncThunk(
 'root/getUserList',
 async () => {
   const response = await apiTaskUserList()
   return response.data.items
 }
)

export const getAuthMe = createAsyncThunk(
 'root/getAuthMe',
 async () => {
   const response = await apiAuthMe()
   return response.data.items
 }
)


export const getLocationList = createAsyncThunk(
 'root/getLocationList',
 async () => {
   const response = await apiTaskUserLocations()
   return response.data.items
 }
)


export interface RootInterface {
  authenticating: boolean,
  taskEnvironment?: TaskEnvironmentInterface,
  auth: boolean,
  date: Date,
  notifications: {
    label: string,
    type?: 'success' | 'error',
    exit?: boolean,
    date?: Date
  }[],
  loadingTaskEnvironment: boolean,
  user?: UserInterface,
  showTypeSelector?: boolean,
  settings: {
    showMobileMenu?: boolean,
    showArchive?: boolean,
    type?: string,
    activeUserId?: number,
    employeeType?: string,
    deliveryType?: string,
    transportType?: string,
    quickBundle?: boolean,
    selectedLocationId?: number
  },
  options: {
    employeeTypes: TypeInterface[],
    deliveryTypes: TypeInterface[],
    transportTypes: TypeInterface[],
    users?: {
      pages: {
        limit: number,
        total: number,
        current: number
      },
      data: UserInterface[]
    },
    locations?: LocationInterface[]
  },

}

const date = new Date()

const initialState: RootInterface = {
  authenticating: true,
  auth: true,
  date: date,
  loadingTaskEnvironment: true,
  notifications: [],
  showTypeSelector: false,
  settings: {
    showMobileMenu: false,
    showArchive: false,
    type: 'transport',
    employeeType: 'transport',
    deliveryType: 'delivery',
    transportType: 'rental',
    activeUserId: 0,
    selectedLocationId: 0
  },
  options: {
    employeeTypes: [
      {
        value: typeEnum.TRANSPORT,
        label: 'Transport'
      },
      {
        value: typeEnum.DEPOT,
        label: 'Depot'
      },
    ],
    deliveryTypes: [
      {
        value: deliveryTypeEnum.DELIVERY,
        label: 'Bezorging'
      },
      {
        value: deliveryTypeEnum.PICKUP,
        label: 'Ophalen'
      },
      {
        value: deliveryTypeEnum.BUNDLE,
        label: 'Bundels'
      }
    ],
    transportTypes: [
      {
        value: transportTypeEnum.RENTAL,
        label: 'Klant'
      },
      {
        value: transportTypeEnum.LOCATION,
        label: 'Filiaal'
      }
    ]
  }
}

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    authToggle: (state) => {
      state.auth = !state.auth
    },
    setNotification: (state, action: PayloadAction<{ label: string, type: 'success' | 'error' }>) => {
      state.notifications.push({
        label: action.payload.label,
        type: action.payload.type,
        date: new Date(),
      })
    },
    updateNotifications: (state) => {
      const date = new Date() as any

      state.notifications = state.notifications.map((notification) => {
        const notificationDate = notification.date as any
        const difference = (date - notificationDate) / 1000;

        if (difference < 3) {
          notification.exit = true
        }

        return notification
      })

      state.notifications = state.notifications.filter((notification) => {
        const notificationDate = notification.date as any
        const difference = (date - notificationDate) / 1000;
        return difference < 1
      })
    },
    toggleTypeSelectorPopup: (state) => {
      state.showTypeSelector = !state.showTypeSelector
    },
    setSettings: (state, action: PayloadAction<RootInterface['settings']>) => {
      state.settings = {
        ...state.settings,
        ...action.payload
      }
    },
    setLocation: (state, action: PayloadAction<number>) => {
      state.settings.selectedLocationId = action.payload
    },
    changeDate: (state, action: PayloadAction<'next' | 'prev'>) => {
      if (action.payload === 'next') {
        state.date = addDays(state.date, 1)
      }
      if (action.payload === 'prev') {
        state.date = subDays(state.date, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(connect.pending, (state) => {
      state.authenticating = true
      state.date = new Date()
    })
    builder.addCase(connect.fulfilled, (state) => {
      state.authenticating = false
    })

    builder.addCase(getEnvironment.fulfilled, (state, action: PayloadAction<TaskEnvironmentInterface>) => {
      state.taskEnvironment = action.payload
      state.loadingTaskEnvironment = false
    })

    builder.addCase(getUserList.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {
      state.options.users = action.payload
    })
    builder.addCase(getLocationList.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {
      if (action.payload) {
        state.options.locations = action.payload
        //state.settings.selectedLocationId = action.payload[0].id
      }
    })
    builder.addCase(getAuthMe.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {
      if (action.payload) {

        state.user = action.payload.user
        switch (state.user?.role?.name) {
          case('depot'):
            state.settings.employeeType = 'depot'
            break;
          case('transport'):
            state.settings.employeeType = 'transport'
            break;
        }
      }
    })
  },
})

export const {
  authToggle,
  setSettings,
  changeDate,
  setLocation,
  setNotification,
  updateNotifications,
  toggleTypeSelectorPopup
} = rootSlice.actions


export default rootSlice.reducer
