import {BundleInterface} from "../../../common/interfaces/BundleInterface";
import {Link} from "react-router-dom";
import {routeEnum} from "../../../common/enums/routeEnum";

interface Props {
    number: number,
    bundle: BundleInterface
}

export const BundleItem = (props: Props) => {

    const {bundle, number} = props

    return (
     <div className="c-bundles__single">
         <div className="c-bundles__single__info">
             <h2>Bundel {number} <Link to={routeEnum.BUNDLE_DETAIL + '/' + bundle.id}>{bundle.id}</Link></h2>
             <div>
                 <span><strong>Aantal taken</strong> {bundle.totals.tasks}</span>
                 <span><strong>Aantal items</strong> {bundle.totals.items}</span>
                 <span><strong>Medewerker</strong> {bundle.user.firstName} {bundle.user.insertion} {bundle.user.lastName}</span>
             </div>
         </div>
         <div className="c-bundles__single__cta"><small>{bundle.totals.tasksDone}/{bundle.totals.tasks} taken
             voltooid</small>
             <Link to={routeEnum.BUNDLE_DETAIL + '/' + bundle.id}
                   className="btn btn--small btn--outline"><span>Bekijken</span></Link>
         </div>
     </div>
    )

}

