import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {ItemInterface} from "../../common/interfaces/ItemInterface";
import {formatScanCode} from "../../common/utils/formatScanCode";
import {apiTaskItemDetailByCode} from "../../common/api/task/item/apiTaskItemDetailByCode";
import {RootInterface, setNotification} from "../../app/rootReducer";
import {apiTaskItemUnlinkAll} from "../../common/api/task/item/apiTaskItemUnlinkAll";
import {apiTaskItemDetail} from "../../common/api/task/item/apiTaskItemDetail";


interface bulkScanStateInterface {
    scannedItems: ItemInterface[],
    submitting: boolean,
    submitted: boolean
}

const initialState: bulkScanStateInterface = {
    scannedItems: [],
    submitting: false,
    submitted: false
}

export const scan = createAsyncThunk(
 'bulkScan/scan',
 async (code: string, {dispatch}) => {
     const scanCodeResult = formatScanCode(code)
     let response
     if (scanCodeResult.type === 'id') {
         response = await apiTaskItemDetail(scanCodeResult.code)
     }else{
          response = await apiTaskItemDetailByCode(scanCodeResult.code)
     }

     if (response?.data.items) {
         return response.data.items as ItemInterface
     } else {
         dispatch(setNotification({
             label: 'Geen item gevonden met code: ' + code,
             type: 'error'
         }))
     }

 }
)

export const submit = createAsyncThunk(
 'bulkScan/submit',
 async (_, {getState}) => {

     const {root} = getState() as { root: RootInterface };

     const {bulkScan} = getState() as { bulkScan: bulkScanStateInterface };
     const {scannedItems} = bulkScan

     if (scannedItems.length) {
         if(!!root.settings.selectedLocationId){
             await apiTaskItemUnlinkAll(scannedItems.map((item) => item.id), root.settings.selectedLocationId)
         }else{
             await apiTaskItemUnlinkAll(scannedItems.map((item) => item.id))
         }
     }

 }
)


const bulkScanSlice = createSlice({
    name: 'bulkScan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(scan.fulfilled, (state, action: PayloadAction<any>) => {

            state.submitted = false

            if (action.payload) {
                if (state.scannedItems.find((scannedItem) => scannedItem.id === action.payload.id)) {
                    state.scannedItems = state.scannedItems.filter((scannedItem) => scannedItem.id !== action.payload.id)
                } else {
                    state.scannedItems.push(action.payload)
                }
            }
        })

        builder.addCase(submit.pending, (state) => {
            state.submitting = true
            state.submitted = false
        })

        builder.addCase(submit.fulfilled, (state) => {
            state.submitting = false
            state.submitted = true
            state.scannedItems = []
        })

    },

})

export const bulkScanReducer = bulkScanSlice.reducer