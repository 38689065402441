import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {mapVariableTaskData} from "../../../utils/mapVariableTaskData";

export const apiTaskInfoDetail = async (id: number) => {
    return await useAppAxios.post(apiEnum.TASK_INFO + '/detail', {}, {
        params: {
            id: id
        }
    }).then(function (response) {

        response.data.items = {
            ...response.data.items,
            variableTaskData: mapVariableTaskData(response.data.items)
        }

        return response

    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
