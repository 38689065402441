import {calculateItemsInCategories} from "../../../common/utils/calculateItemsInCategories";
import {TaskCategoryInterface} from "../../../common/interfaces/TaskCategoryInterface";
import {ItemInterface} from "../../../common/interfaces/ItemInterface";
import {CategoryInterface} from "../../../common/interfaces/CategoryInterface";
import {Notification} from "../../../common/components/Notification";
import {DevScan} from "../../../common/components/DevScan";
import {scan} from "../TaskDetailSlice";
import {useAppDispatch} from "../../../app/hooks";

interface Props {
    categoriesToScan: {
        category?: CategoryInterface,
        item?: ItemInterface,
        scanned: boolean
    }[],
    title: string,
    categories?: TaskCategoryInterface[]
}

export const ScannableCategoriesCard = (props: Props) => {

    const {categories, categoriesToScan} = props
    let categoriesAmount = calculateItemsInCategories(categories)
    let scannedItems = categoriesToScan.filter((categoryToScan) => categoryToScan.scanned)
    const dispatch = useAppDispatch()

    return (
     <div className="c-task-scan">

         {props.categories &&
         <header className="c-task-scan__header">

             <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                 <h2>Scan items om deze aan de taak toe te voegen <small>{categoriesAmount} items</small></h2>
                 <DevScan onScan={(value: any) => dispatch(scan(value + ''))}/>
             </div>
             <ul className="u-reset-ul">
                 {props.categories.map((taskCategory) => {
                     return (<li>
                          <span>{taskCategory.quantity}x {taskCategory.category.alternativeLabel ? taskCategory.category.alternativeLabel : taskCategory.category.name}</span>
                      </li>
                     )
                 })}
             </ul>


         </header>
         }

         {!!categories?.length &&
         <div className="c-task-scan__content">
             <table className="c-table-standard">

                 <tr>
                     <th/>
                     <th>Categorie</th>
                     <th>Merk en model</th>
                     <th>Productcode</th>
                 </tr>

                 {categoriesToScan?.map((categoryToScan) => {

                     let className = 'noScan'
                     let scanned = false

                     if (categoryToScan.scanned) {
                         className = ''
                         scanned = true
                     }

                     return (
                      <tr className={className}>
                          <td>{scanned && <i className="icon-check-circle"/>}</td>
                          <td>{categoryToScan.category?.alternativeLabel ? categoryToScan.category.alternativeLabel : categoryToScan.category?.name}</td>
                          {categoryToScan.item &&
                          <>
                              <td>{categoryToScan.item.brandModelLabel}</td>
                              <td>{categoryToScan.item.productCode}</td>
                          </>
                          }
                      </tr>
                     )


                 })}

             </table>

             {scannedItems.length === categoriesToScan.length &&
             <Notification type={'success'} text={'Alle items gescand.'}/>}

         </div>
         }
     </div>
    )
}