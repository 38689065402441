import ReactPaginate from "react-paginate";
import {TaskInterface} from "../interfaces/TaskInterface";

interface Props {
  tasks?: {
    data?: TaskInterface[],
    pages?: {
      current: number,
      limit: number,
      total: number
    },
  },
  pageClickAction: any
}

export const DashboardPagination = (props: Props) => {

  const {tasks} = props;

  const handlePageClick = (data: any) => {
    props.pageClickAction(data.selected)
  }

  if (tasks && tasks.pages && tasks.pages?.total > 1) {
    return (
     <div className="c-pagination">

       <ReactPaginate
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Number(tasks.pages?.total)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        forcePage={Number(tasks.pages?.current)}
        activeClassName={'isActive'}
       />

     </div>
    )
  } else return null
}