import {mapVariableTaskData} from "./mapVariableTaskData";
import {TaskInterface} from "../interfaces/TaskInterface";
import {mapNumberToAlphabet} from "./mapNumberToAlphabet";

export interface LocationMarkerInterface {
    label: string,
    markerValue: string,
    lat?: number,
    long?: number
}

export interface LocationsMarkerInterface {
    from: LocationMarkerInterface[]
    to: LocationMarkerInterface[]
    all: LocationMarkerInterface[]

}

export const mapTaskLocationsToMarkers = (tasks?: TaskInterface[]) => {

    const locationMarkers = {from: [], to: [], all: []} as LocationsMarkerInterface

    let locationFromNumber = 0
    let locationToNumber = 0

    tasks?.forEach((task) => {


        const variableTaskData = mapVariableTaskData(task)
        const {locations} = variableTaskData
        const locationFrom = locations.from
        const locationTo = locations.to

        if (locationFrom.label && !locationMarkers.from.find((locationMarker) => locationMarker.label === locationFrom.label)) {
            locationFromNumber++

            if (locationFrom.lat && locationFrom.long) {

                locationMarkers.from.push({
                    label: locationFrom.label,
                    markerValue: mapNumberToAlphabet(locationFromNumber) + '',
                    lat: locationFrom.lat,
                    long: locationFrom.long
                })

                locationMarkers.all.push(
                    {
                        label: locationFrom.label,
                        markerValue: mapNumberToAlphabet(locationFromNumber) + '',
                        lat: locationFrom.lat,
                        long: locationFrom.long
                    }
                )

            }
        }

        if (locationTo.label && !locationMarkers.to.find((locationMarker) => {
            return locationMarker.label === locationTo.label
        })) {
            locationToNumber++

            if (locationTo.lat && locationTo.long) {


                locationMarkers.to.push({
                    label: locationTo.label,
                    markerValue: locationToNumber + '',
                    lat: locationTo.lat,
                    long: locationTo.long
                })

                locationMarkers.all.push({
                    label: locationTo.label,
                    markerValue: locationToNumber + '',
                    lat: locationTo.lat,
                    long: locationTo.long
                })
            }
        }


    })

    return locationMarkers

}