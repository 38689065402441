import {useNavigate} from 'react-router-dom';
import {BranchSelect} from "../../common/components/BranchSelect";
import {BulkScanHeader} from "./components/BulkScanHeader";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {DevScan} from "../../common/components/DevScan";
import BarcodeReader from "react-barcode-reader";
import {scan, submit} from "./bulkScanSlice";
import {Notification} from "../../common/components/Notification";

export const BulkScanPageContainer = () => {

    const navigate = useNavigate();
    const {scannedItems, submitting, submitted} = useAppSelector(state => state.bulkScan);
    const dispatch = useAppDispatch()

    return (
     <>
         <BarcodeReader onScan={(value: any) => dispatch(scan(value + ''))}/>

         <BulkScanHeader goBack={() => navigate(-1)}/>

         <div className="o-container o-container--primary">

             <div className="c-scan">

                 <header className="c-subject-header c-subject-header--split">
                     <div className="c-subject-header__update" style={{width: '100%'}}>
                         <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                             <h2>Verwerk items</h2>
                             <DevScan onScan={(value: any) => dispatch(scan(value + ''))}/>
                         </div>
                         {!!scannedItems.length &&
                         <div className="c-subject-header__buttons" onClick={() => dispatch(submit())}>
                             <button className="btn btn--small btn--success" disabled={submitting}>
                                 <span>Verwerken</span></button>
                         </div>
                         }
                     </div>

                     <BranchSelect/>

                 </header>

                 {submitted && <Notification type={'success'} text={'Items verwerkt'}/>}


                 <div className="c-task ">
                     <div className="c-task-scan">

                         <div className="c-task-scan__content c-task-scan__content--bulk">
                             {!scannedItems.length &&
                             <span>Nog geen items gescand..</span>
                             }

                             {!!scannedItems.length &&
                             <table className="c-table-standard">

                                 <tbody>

                                 <tr>
                                     <th/>
                                     <th>Categorie</th>
                                     <th>Productcode</th>
                                     <th>Taak</th>
                                     <th>Status</th>
                                 </tr>

                                 {scannedItems.map((scannedItem) => {
                                     return (
                                      <tr>
                                          <td><i className="icon-check-circle"/></td>
                                          <td>{scannedItem.category.alternativeLabel ? scannedItem.category.alternativeLabel : scannedItem.category.name}</td>
                                          <td>{scannedItem.productCode}</td>
                                          <td>{scannedItem.task?.id}</td>
                                          <td>{scannedItem.status}</td>
                                      </tr>
                                     )
                                 })}

                                 </tbody>

                             </table>
                             }
                         </div>
                     </div>
                 </div>
             </div>
         </div>

     </>
    )

}