import {BundleInterface} from "../interfaces/BundleInterface";
import {deliveryTypeEnum} from "../enums/deliveryTypeEnum";
import {TaskCategoryToScanInterface} from "../interfaces/TaskCategoryToScanInterface";
import {bundleEnablePrepareItems} from "./bundleEnablePrepareItems";

export const displayBundleActionButton = (bundle: BundleInterface, taskCategoriesToScan?: TaskCategoryToScanInterface[]) => {

    if (bundleEnablePrepareItems(bundle) ||
     (bundle.deliveryType.code === deliveryTypeEnum.PICKUP && bundle.status === 'delivered')) {

        if (taskCategoriesToScan) {


            const emptyCategory = taskCategoriesToScan.find((taskCategory) => taskCategory.categories.find((categoryToScan) => {
                return !categoryToScan.scanned
            }))

            return !emptyCategory

        } else return false

    } else return true

}