import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {loadTasks, reset, submit} from "./bundleCreateSlice";
import {Link, useNavigate} from "react-router-dom";
import {routeEnum} from "../../common/enums/routeEnum";
import {setStep} from "./bundleCreateSlice";
import {SelectTasks} from "./segments/SelectTasks";
import {OrderTasks} from "./segments/OrderTasks";
import {BranchSelect} from "../../common/components/BranchSelect";
import {deliveryTypeEnum} from "../../common/enums/deliveryTypeEnum";
import {setNotification} from "../../app/rootReducer";

export const BundleCreatePageContainer = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const {settings, taskEnvironment} = useAppSelector(state => state.root);
    const {
        tasks,
        activePage,
        activeStep,
        bundleTasks,
        submitResponse,
        submitting,
        submitted
    } = useAppSelector(state => state.bundleCreate);

    useEffect(() => {
        dispatch(loadTasks())
    }, [activePage])

    useEffect(() => {

        if (submitResponse && submitResponse.items.id) {
            dispatch(setNotification({
                label: 'Bundel ' + submitResponse.items.id + ' aangemaakt',
                type: 'success'
            }))
            navigate('/' + taskEnvironment?.info.clientId + '/' + routeEnum.BUNDLE_DETAIL + '/' + submitResponse.items.id)
            dispatch(reset())
        }

    }, [submitResponse])


    if (tasks) {

        return (

         <>
             <div className="c-subheader c-subheader--detail">
                 <div className="c-subheader__task">
                     {settings.deliveryType === deliveryTypeEnum.DELIVERY &&
                     <h1>Bezorg bundel aanmaken</h1>
                     }

                     {settings.deliveryType === deliveryTypeEnum.PICKUP &&
                     <h1>Ophaal bundel aanmaken</h1>
                     }
                 </div>
                 <div className="c-subheader__misc">
                     {(activeStep === 1 && !!bundleTasks.length) &&
                     <button onClick={() => dispatch(setStep(2))} className="btn btn--small btn--primary">
                         <span>{activeStep}/2 Volgorde bepalen</span>
                     </button>
                     }

                     {activeStep === 2 &&
                     <button disabled={!!submitting && !!submitted} onClick={() => dispatch(submit())}
                             className="btn btn--small btn--primary">
                         <span>Bundel aanmaken</span>
                     </button>
                     }

                     <BranchSelect/>

                     <Link className={'btn btn--small btn--outline'} to={'/' + taskEnvironment?.info.clientId + '/' + routeEnum.BUNDLE_DETAIL}>
                         <span>Annuleren</span>
                     </Link>
                 </div>
             </div>

             {activeStep === 1 &&
             <SelectTasks tasks={tasks} bundleTasks={bundleTasks}/>
             }

             {activeStep === 2 &&
             <OrderTasks bundleTasks={bundleTasks}/>
             }

         </>
        )
    } else return null

}