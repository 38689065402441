// converts numbers to spreadsheet letter columns eg. 1 -> A
export const mapNumberToAlphabet = (num: any) => {
    let s = '', t;

    while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = (num - t) / 26 | 0;
    }
    return s || undefined;
}
