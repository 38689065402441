import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {load, scan, updateBundleUser} from "./bundleDetailSlice";
import {useParams} from "react-router";
import {BundleTaskCard} from "./components/BundleTaskCard";
import {mapTaskLocationsToMarkers} from "../../common/utils/mapTaskLocationsToMarkers";
import ReactMapboxGl, {Marker} from "react-mapbox-gl";
import {calculateMapBounds} from "../../common/utils/calculateMapBounds";
import {BundlePrepareItemsCard} from "./components/BundlePrepareItemsCard";
import {deliveryTypeEnum} from "../../common/enums/deliveryTypeEnum";
import {SelectUserCard} from "../../common/components/SelectUserCard";
import BarcodeReader from "react-barcode-reader";
import {Notification} from "../../common/components/Notification";
import {displayBundleActionButton} from "../../common/utils/displayBundleActionButton";
import {Loader} from "../../common/components/Loader";
import {setNotification} from "../../app/rootReducer";
import {bundleEnablePrepareItems} from "../../common/utils/bundleEnablePrepareItems";

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiYmFydDk5MiIsImEiOiJja2t6YWZvNjA1a2NnMnBxdHR3OWcwamVxIn0.jmqJ1sdkWatA2frBRr3vZg'
});

export const BundleDetailPageContainer = () => {

    let {id} = useParams();

    const {options} = useAppSelector(state => state.root);
    const {users} = options

    const {
        bundle,
        loading,
        updateBundle,
        taskCategoriesToScan,
        selectedUserId
    } = useAppSelector(state => state.bundleDetail);
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(load(Number(id)))
    }, [])

    useEffect(() => {

        if (updateBundle.updated) {
            dispatch(setNotification({
                label: 'Bundel ' + bundle?.id + ' geupdatet',
                type: 'success'
            }))
            dispatch(load(Number(id)))
        }

    }, [updateBundle.updated])


    if (!loading && !updateBundle.updating) {
        if (bundle) {
            const locationMarkers = mapTaskLocationsToMarkers(bundle.tasks)
            const bounds = calculateMapBounds(locationMarkers.all) as any
            const displayBundleAction = displayBundleActionButton(bundle, taskCategoriesToScan)

            let actionHeaderClass = 'c-subject-header c-subject-header--update'

            if (!displayBundleAction) {
                actionHeaderClass += ' isDisabled'
            }

            return (
             <div className="o-container o-container--primary">

                 <BarcodeReader onScan={(value: any) => dispatch(scan(value + ''))}/>

                 <div className="c-bundle">

                     <header className={actionHeaderClass}>

                         {(bundle.variableBundleData.action) &&
                         <>
                             <h2>Update status voor bundel:</h2>

                             <div className="c-subject-header__buttons">

                                 <button
                                     onClick={() => dispatch(bundle.variableBundleData.action.dispatchAction)}
                                     className="btn btn--small btn--success">
                                     <span>{bundle.variableBundleData.action.label}</span>
                                 </button>

                             </div>
                         </>
                         }
                     </header>


                     {bundleEnablePrepareItems(bundle) &&

                     <>

                         {displayBundleAction && <Notification type={'success'} text={'Alle items gescant'}/>}

                         <BundlePrepareItemsCard
                             taskCategoriesToScan={taskCategoriesToScan}
                             tasks={bundle.tasks}
                         />

                     </>

                     }

                     {displayBundleAction && users?.data &&
                     <>
                         <SelectUserCard
                             users={users.data}
                             selectedUserId={selectedUserId}
                             dispatchAction={updateBundleUser}
                         />

                     </>

                     }


                     {(bundle.deliveryType.code === deliveryTypeEnum.PICKUP && bundle.status === 'delivered') &&
                     <>

                         <BundlePrepareItemsCard
                             taskCategoriesToScan={taskCategoriesToScan}
                             tasks={bundle.tasks}
                         />
                         {displayBundleAction && <Notification type={'success'} text={'Alle items gescant'}/>}
                     </>
                     }


                     {bundle.user &&
                     <span>
                        <strong>Medewerker </strong>
                         {bundle.user.firstName} {bundle.user.insertion} {bundle.user.lastName}
                    </span>
                     }

                     <div className="c-bundle__info">
                         <div className="c-bundle__map">
                             {bounds &&
                             <Map
                                 fitBounds={[bounds[0], bounds[1]]}
                                 style="mapbox://styles/bart992/ckkzaiqbv3ex217oaq5q1ymsm"
                                 containerStyle={{
                                     height: '100%',
                                     width: '100%'
                                 }}
                             >

                                 <>
                                     {locationMarkers.from.map((locationMarker) => {


                                         if (locationMarker.lat && locationMarker.long) {
                                             return (
                                              <Marker
                                               coordinates={[Number(locationMarker?.long), Number(locationMarker?.lat)]}
                                               anchor="bottom">
                                                  <div className="c-tag">
                                                      <small>{locationMarker.markerValue}</small>
                                                  </div>
                                              </Marker>
                                             )
                                         }
                                     })}

                                     {locationMarkers.to.map((locationMarker) => {
                                         if (locationMarker.lat && locationMarker.long) {
                                             return (
                                              <Marker
                                               coordinates={[Number(locationMarker?.long), Number(locationMarker?.lat)]}
                                               anchor="bottom">
                                                  <div className="c-tag c-tag--black">
                                                      <small>{locationMarker.markerValue}</small>
                                                  </div>
                                              </Marker>
                                             )
                                         }
                                     })}
                                 </>
                             </Map>
                             }
                         </div>

                         <div className="c-bundle__wrap">
                             {bundle.tasks.map((task) => {
                                 return (<BundleTaskCard locationMarkers={locationMarkers} task={task}/>)
                             })}
                         </div>

                     </div>

                 </div>

             </div>
            )

        } else return null
    } else return <Loader/>

}