import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {TaskOrderInterface} from "../../../interfaces/TaskOrderInterface";

export const apiTaskBundleUpdateTaskOrder = async (id: number, orderTasks: TaskOrderInterface[]) => {
    return await useAppAxios.post(apiEnum.TASK_BUNDLE + '/update-task-order', {
        tasks: orderTasks
    }, {
        params: {
            id: id
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
