interface Props {
    type: string,
    text: string
}

export const Notification = (props: Props) => {

    return (
        <div className={'c-notification c-notification--' + props.type}>
            <i className="icon icon-info"/>
            <span>{props.text}</span>
        </div>
    )

}