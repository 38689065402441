import {TaskInterface} from "../interfaces/TaskInterface";
import {CategoryToScanInterface} from "../interfaces/CategoryToScanInterface";


export const mapCategoriesToScan = (task: TaskInterface, categoriesToScanState: CategoryToScanInterface[]) => {

    task?.categories?.forEach((taskCategory) => {
        [...Array(taskCategory.quantity)].forEach(() => {
            categoriesToScanState.push({
                category: taskCategory.category,
                scanned: false,
            })
        })
    })
    task?.items?.forEach((taskItem) => {
        const categoryToScanIndex = categoriesToScanState.findIndex((categoryToScan) => {
            return categoryToScan.category?.id === taskItem.category.id && !categoryToScan.item
        })

        if (categoryToScanIndex !== -1) {

            categoriesToScanState = categoriesToScanState.map((categoryToScan, index) => {

                if (index === categoryToScanIndex) {

                    return {
                        ...categoryToScan,
                        item: taskItem,
                        persistent: true,
                    }
                } else return categoryToScan
            })

        }

    })

    return categoriesToScanState

}