import {TaskInterface} from "../interfaces/TaskInterface";
import {routeEnum} from "../enums/routeEnum";
import {updateTaskStatus} from "../../views/taskDetailPage/TaskDetailSlice";
import {transportTypeEnum} from "../enums/transportTypeEnum";
import {formatPartnerLocationName} from "./formatPartnerLocationName";
import {formatAddressName} from "./formatAddressName";

export interface VariableTaskLocation {
    label?: string,
    lat?: number,
    long?: number
}

export interface VariableTaskData {
    locations: {
        from: VariableTaskLocation,
        to: VariableTaskLocation,
    },
    action?: {
        label: string,
        dispatchAction: any
    },
    time: {
        label?: string,
        value?: string
    },
    detailLink: string

}

export const mapVariableTaskData = (task: TaskInterface) => {

    let variableTaskData = {} as VariableTaskData

    const {deliveryAddress, deliveryAddressItem, deliveryAddressNumber, pickupAddress, pickupAddressItem, pickupAddressNumber} = task
    const deliveryLabel = formatAddressName(deliveryAddress, deliveryAddressItem, deliveryAddressNumber)
    const pickupLabel = formatAddressName(pickupAddress, pickupAddressItem, pickupAddressNumber)

    console.log(deliveryAddress, deliveryAddressItem, deliveryAddressNumber)

    switch (task.deliveryType?.code) {
        case('delivery'):

            if (task.transportType.code === transportTypeEnum.RENTAL) {

                variableTaskData = {
                    locations: {
                        from: {
                            label: formatPartnerLocationName(task.pickupPartnerLocation),
                            lat: task.pickupPartnerLocation?.lat,
                            long: task.pickupPartnerLocation?.long,
                        },
                        to: {
                            label: deliveryLabel,
                            lat: task.deliveryAddress?.lat,
                            long: task.deliveryAddress?.long,
                        }
                    },
                    time: {
                        label: 'Bezorgtijd',
                        value: task.rental?.deliveryTime
                    },
                    detailLink: routeEnum.DELIVERY_DETAIL + '/' + task.id
                }
            }

            if (task.transportType.code === transportTypeEnum.LOCATION) {
                variableTaskData = {
                    locations: {
                        from: {
                            label: formatPartnerLocationName(task.pickupPartnerLocation),
                            lat: task.pickupPartnerLocation?.lat,
                            long: task.pickupPartnerLocation?.long,
                        },
                        to: {
                            label: formatPartnerLocationName(task.deliveryPartnerLocation),
                            lat: task.deliveryPartnerLocation?.lat,
                            long: task.deliveryPartnerLocation?.long,
                        }
                    },
                    time: {
                        label: 'Bezorgtijd',
                        value: task.rental?.deliveryTime
                    },
                    detailLink: routeEnum.DELIVERY_DETAIL + '/' + task.id
                }
            }


            switch (task.status.code) {
                case('open'):

                    variableTaskData = {
                        ...variableTaskData,
                        action: {
                            label: 'Klaargezet',
                            dispatchAction: updateTaskStatus({
                                id: task.id,
                                status: 'prepared'
                            })
                        },
                    }

                    // depot
                    break;
                case('prepared'):

                    variableTaskData = {
                        ...variableTaskData,
                        action: {
                            label: 'Ik ben onderweg',
                            dispatchAction: updateTaskStatus({
                                id: task.id,
                                status: 'doing'
                            })
                        },
                    }

                    break;
                case('doing'):

                    variableTaskData = {
                        ...variableTaskData,
                        action: {
                            label: 'Items afgeleverd',
                            dispatchAction: updateTaskStatus({
                                id: task.id,
                                status: 'done'
                            })
                        },
                    }

                    break;
                case('done'):

                    //transport

                    break;
            }

            break;

        case('pickup'):


            if (task.transportType.code === transportTypeEnum.RENTAL) {

                variableTaskData = {
                    locations: {
                        from: {
                            label: pickupLabel,
                            lat: task.pickupAddress?.lat,
                            long: task.pickupAddress?.long,
                        },
                        to: {
                            label: formatPartnerLocationName(task.deliveryPartnerLocation),
                            lat: task.deliveryPartnerLocation?.lat,
                            long: task.deliveryPartnerLocation?.long,
                        }
                    },
                    detailLink: routeEnum.PICKUP_DETAIL + '/' + task.id,
                    time: {
                        label: 'Ophaaltijd',
                        value: task.rental?.pickUpTime
                    }
                }
            }

            if (task.transportType.code === transportTypeEnum.LOCATION) {
                variableTaskData = {
                    locations: {
                        from: {
                            label: formatPartnerLocationName(task.pickupPartnerLocation),
                            lat: task.pickupPartnerLocation?.lat,
                            long: task.pickupPartnerLocation?.long,
                        },
                        to: {
                            label: formatPartnerLocationName(task.deliveryPartnerLocation),
                            lat: task.deliveryPartnerLocation?.lat,
                            long: task.deliveryPartnerLocation?.long,
                        }
                    },
                    detailLink: routeEnum.PICKUP_DETAIL + '/' + task.id,
                    time: {
                        label: 'Ophaaltijd',
                        value: task.rental?.pickUpTime
                    }
                }
            }

            switch (task.status.code) {
                case('open'):
                    variableTaskData = {
                        ...variableTaskData,
                        action: {
                            label: 'Ik ben onderweg',
                            dispatchAction: updateTaskStatus({
                                id: task.id,
                                status: 'doing'
                            })
                        },
                    }

                    // depot
                    break;
                case('doing'):

                    if (task.transportType.code === transportTypeEnum.LOCATION) {

                        variableTaskData = {
                            ...variableTaskData,
                            action: {
                                label: 'Items opgehaald en in depot',
                                dispatchAction: updateTaskStatus({
                                    id: task.id,
                                    status: 'done'
                                })
                            },
                        }

                    }else {
                        variableTaskData = {
                            ...variableTaskData,
                            action: {
                                label: 'Items opgehaald',
                                dispatchAction: updateTaskStatus({
                                    id: task.id,
                                    status: 'delivered'
                                })
                            },
                        }
                    }

                    break;
                case('delivered'):
                    variableTaskData = {
                        ...variableTaskData,
                        action: {
                            label: 'Verwerkt',
                            dispatchAction: updateTaskStatus({
                                id: task.id,
                                status: 'done'
                            })
                        },
                    }

                    break;

                case('done'):
                    //transport

                    break;
            }

            break;

    }

    return variableTaskData
}