import {RentalInterface} from "../interfaces/RentalInterface";

export const formatRentalCustomerData = (rental?: RentalInterface) => {

  if (rental) {

    if (rental.customer?.privatePerson) {

      const {privatePerson} = rental.customer
      let name = ''

      if (privatePerson.firstName) {
        name += ' ' + privatePerson.firstName
      }

      if (privatePerson.insertion) {
        name += ' ' + privatePerson.insertion
      }

      if (privatePerson.lastName) {
        name += ' ' + privatePerson.lastName
      }

      return {
        email: rental.customer.privatePerson.email,
        name: name,
        phone: rental.customer.privatePerson.telephone
      }

    }

    if (rental.customer?.contactPerson) {

      const {contactPerson} = rental.customer

      let name = contactPerson.firstName

      if (contactPerson.insertion) {
        name += ' ' + contactPerson.insertion
      }

      if (contactPerson.lastName) {
        name += ' ' + contactPerson.lastName
      }

      return {
        email: rental.customer?.contactPerson.email,
        name: name,
        phone: rental.customer?.contactPerson.telephone
      }

    }


  }

  return null

}

