import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {BundleInterface} from "../../common/interfaces/BundleInterface";
import {TaskInterface} from "../../common/interfaces/TaskInterface";
import {AxiosResponse} from "axios";
import {RootInterface} from "../../app/rootReducer";
import {apiTaskInfoList} from "../../common/api/task/info/apiTaskInfoList";
import {apiTaskBundleCreate} from "../../common/api/task/bundle/apiTaskBundleCreate";
import {format} from "date-fns";
import {apiTaskBundleUpdateTaskOrder} from "../../common/api/task/bundle/apiTaskBundleUpdateTaskOrder";

interface BundleCreateInterface {
  example?: string,
  loading?: boolean,
  submitting?: boolean,
  submitted?: boolean,
  submitResponse?: AxiosResponse['data'],
  bundle?: BundleInterface,
  bundleTasks: TaskInterface[]
  tasks?: {
    data?: TaskInterface[]
    pages?: {
      current: number,
      limit: number,
      total: number
    },
  },
  activePage?: number,
  activeStep?: number,
}

const initialState: BundleCreateInterface = {
  bundleTasks: [],
  activeStep: 1
}

export const loadTasks = createAsyncThunk(
 'bundleCreate/loadTasks',
 async (_, {getState}) => {
   const {root} = getState() as { root: RootInterface };
   const {bundleCreate} = getState() as { bundleCreate: BundleCreateInterface };

   if (root.settings.type && root.settings.transportType && root.settings.deliveryType && root.settings.employeeType) {
     const response = await apiTaskInfoList(root.settings.type, root.settings.deliveryType, root.settings.transportType, root.settings.employeeType, root.date, false, true, 4, bundleCreate.activePage)
     return response.data
   }
 }
)

export const submit = createAsyncThunk(
 'bundleCreate/submit',
 async (_, {getState}) => {
   const {root} = getState() as { root: RootInterface };
   const {bundleCreate} = getState() as { bundleCreate: BundleCreateInterface };
   const taskIds = bundleCreate.bundleTasks.map((bundleTask) => {
     return bundleTask.id
   })
   const orderedTaskIds = bundleCreate.bundleTasks.map((bundleTask) => {
     return {
       taskId: bundleTask.id,
       order: bundleTask.custom?.order
     }
   })
   const user = JSON.parse(localStorage.getItem('user') + '');


   const submitResponse = await apiTaskBundleCreate(user.id, format(root.date, 'yyyy-MM-dd'), taskIds, root.settings.type + '', root.settings.transportType + '', root.settings.deliveryType + '', root.settings.selectedLocationId)
   await apiTaskBundleUpdateTaskOrder(submitResponse.data.items.id, orderedTaskIds)

   return submitResponse.data

 }
)

const BundleCreateSlice = createSlice({
  name: 'bundleCreate',
  initialState,
  reducers: {
    reset: () => initialState,
    setPage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setBundleTask: (state, action: PayloadAction<TaskInterface>) => {

      if (state.bundleTasks.find((bundleTask) => bundleTask.id === action.payload.id)) {
        state.bundleTasks = state.bundleTasks.filter((task) => task.id !== action.payload.id)
      } else {

        const bundleTask = {
          ...action.payload,
          custom: {
            order: state.bundleTasks.length + 1
          }
        }

        state.bundleTasks.push(bundleTask)
      }
    },
    changeOrder: (state, action: PayloadAction<{ id: number, order: -1 | 1 }>) => {

      const bundleTask = state.bundleTasks.find((bundleTask) => bundleTask.id === action.payload.id)

      if (bundleTask && bundleTask.custom) {

        const order = bundleTask.custom.order

        if (order) {

          const sibling = state.bundleTasks.find((task) => {
            if (task.custom) {

              return task.custom.order === order + action.payload.order

            } else return null
          })

          if (sibling && sibling.custom && sibling.custom.order) {

            if (action.payload.order === 1) {

              sibling.custom.order = sibling.custom.order - 1

            } else {

              sibling.custom.order = sibling.custom.order + 1

            }

            state.bundleTasks.map((task) => {
              if (task.id === sibling.id) {
                return {
                  ...sibling
                }
              } else return {
                ...task
              }
            })

          }

        }

        bundleTask.custom.order = Number(bundleTask.custom.order) + action.payload.order

        state.bundleTasks.map((task) => {
          if (task.id === bundleTask.id) {
            return {
              ...bundleTask
            }
          } else return task
        })

      }

    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadTasks.pending, (state) => {
      state.loading = true
    })
    builder.addCase(loadTasks.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {

      if (action.payload) {
        state.tasks = action.payload.items
      }

      state.loading = false

    })
    builder.addCase(submit.pending, (state) => {
      state.submitting = true
    })
    builder.addCase(submit.fulfilled, (state, action: PayloadAction<AxiosResponse['data']>) => {
      state.submitResponse = action.payload
      state.submitting = false
    })
  },
})

export const {setPage, setBundleTask, setStep, changeOrder, reset} = BundleCreateSlice.actions
export const bundleCreateReducer = BundleCreateSlice.reducer
