import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "./hooks";
import {connect, getLocationList, getUserList, getAuthMe, getEnvironment} from "./rootReducer";
import {LoginPageContainer} from "../views/loginPage/LoginPageContainer";
import {PinCodePageContainer} from "../views/pincodePage/PinCodePageContainer";
import {AppHeader} from "../common/components/AppHeader";
import {AppFooter} from "../common/components/AppFooter";
import {DashboardPageContainer} from "../views/dashboardPage/DashboardPageContainer";
import {TypeSelectOverlay} from "../common/components/TypeSelectOverlay";
import {setSettings} from "./rootReducer";
import {TypeInterface} from "../common/interfaces/TypeInterface";
import {authToggle} from "./rootReducer";
import {Routes} from "react-router-dom";
import {Route, useParams} from "react-router";
import {BulkScanPageContainer} from "../views/bulkScanPage/BulkScanPageContainer";
import {routeEnum} from "../common/enums/routeEnum";
import {TaskDetailPageContainer} from "../views/taskDetailPage/TaskDetailPageContainer";
import {BundleDetailPageContainer} from "../views/bundleDetailPage/BundleDetailPageContainer";
import {BundleCreatePageContainer} from "../views/bundleCreatePage/BundleCreatePageContainer";
import {toggleTypeSelectorPopup} from "./rootReducer";
import {EnvironmentNotFoundPageContainer} from "../views/environmentNotFound/EnvironmentNotFoundPageContainer";
import {Loader} from "../common/components/Loader";

function App() {

    let {id} = useParams();
    const dispatch = useAppDispatch();
    const {auth, date, taskEnvironment, loadingTaskEnvironment} = useAppSelector(state => state.root);
    const {options, settings, showTypeSelector} = useAppSelector(state => state.root);
    const activeType = options.employeeTypes.find((employeeType: TypeInterface) => employeeType.value === settings.employeeType)


    useEffect(() => {
         dispatch(connect()).then(() => {
             dispatch(getEnvironment(Number(id))).then(() => {
                 if (localStorage.getItem('user')) {
                     dispatch(getUserList())
                     dispatch(getAuthMe())
                     dispatch(getLocationList())
                 }
             })
         })
     }, [dispatch, auth]
    );


    useEffect(() => {
        /**
         setInterval(() => {
            dispatch(updateNotifications());
        }, 10000) */

    }, [])

    if (taskEnvironment && !loadingTaskEnvironment) {
        return (
         <>
             <style dangerouslySetInnerHTML={{
                 __html: `
           
                  .c-tabs__navigation .isActive:before, .c-tag{
                    background-color: ` + taskEnvironment.info.primaryColorLight + ` !important;
                  }
                  
                  .btn--outline i:before, .c-tasks__single__select div i:before, .btn--outline i, .btn--outline span{
                    color: ` + taskEnvironment.info.primaryColorDark + ` !important;
                  }
                  
                  
                  .c-tag--black{
                     background-color: ` + taskEnvironment.info.primaryColorDark + ` !important;
                  }
                  
                  .c-task-appoint .isAppointed .c-tasks__single__select div{
                    border-color: ` + taskEnvironment.info.primaryColorLight + ` !important;
                  }
                  


                 `
             }}/>


             {/** <Notifications/> */}

             {(localStorage.getItem('user') && localStorage.getItem(taskEnvironment.info.clientId + '-client')) ?
              <>
                  <AppHeader
                   date={date}
                   toggleTypeSelect={() => dispatch(toggleTypeSelectorPopup())}
                   switchUser={() => {
                       localStorage.removeItem('user')
                       dispatch(authToggle())
                   }}
                   logout={() => {
                       localStorage.removeItem('user')
                       localStorage.removeItem(taskEnvironment.info.clientId + '-client')
                       dispatch(authToggle())
                   }}
                   activeType={activeType}

                  />

                  {showTypeSelector &&
                  <TypeSelectOverlay
                      toggleTypeSelect={() => dispatch(toggleTypeSelectorPopup())}
                      options={options.employeeTypes}
                      activeType={activeType}
                      setActiveType={(value: string) => dispatch(setSettings({
                          employeeType: value,
                      }))}
                  />
                  }

                  <main>


                      <Routes>

                          <Route path={routeEnum.BULK_SCAN} element={<BulkScanPageContainer/>}/>
                          <Route path={routeEnum.DELIVERY_DETAIL + '/:id'}
                                 element={<TaskDetailPageContainer/>}/>
                          <Route path={routeEnum.PICKUP_DETAIL + '/:id'}
                                 element={<TaskDetailPageContainer/>}/>
                          <Route path={routeEnum.BUNDLE_DETAIL + '/:id'} element={<BundleDetailPageContainer/>}/>
                          <Route path={routeEnum.BUNDLE_CREATE} element={<BundleCreatePageContainer/>}/>
                          <Route index element={<DashboardPageContainer/>}/>

                      </Routes>
                  </main>
                  <AppFooter/>
              </>
              :
              <>
                  {localStorage.getItem(taskEnvironment.info.clientId + '-client') ?
                   <PinCodePageContainer/>
                   :
                   <LoginPageContainer/>
                  }
              </>
             }
         </>
        );
    } else if (loadingTaskEnvironment) {
        return <Loader/>
    } else return <EnvironmentNotFoundPageContainer/>
}

export default App;
