import {TaskInterface} from "../interfaces/TaskInterface";

interface tasksCategoryQuantity {
    label: string
    id: number,
    quantity: number
}

export const mapTasksCategoryQuantities = (tasks: TaskInterface[]) => {

    const tasksCategoryQuantities = [] as tasksCategoryQuantity[]

    tasks.forEach((task) => {

        task.categories?.forEach((taskCategory) => {

            const index = tasksCategoryQuantities.findIndex((categoryQuantity) => categoryQuantity.id === taskCategory.category.id)

            if (index !== -1) {

                tasksCategoryQuantities[index].quantity += taskCategory.quantity

            } else {


                tasksCategoryQuantities.push({
                    label: taskCategory.category.alternativeLabel ? taskCategory.category.alternativeLabel : taskCategory.category.name,
                    id: taskCategory.category.id,
                    quantity: taskCategory.quantity
                })

            }

        })

    })

    return tasksCategoryQuantities

}