import {DashboardHeader} from "./components/DashboardHeader";
import {DashboardTabs} from "./components/DashboardTabs";

import {DashboardPagination} from "../../common/components/Pagination";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {TypeInterface} from "../../common/interfaces/TypeInterface";
import {setSettings} from "../../app/rootReducer";
import {useEffect} from "react";
import {loadBundles, loadTasks, loadTotals, resetQuickBundle, setPage} from "./DashboardSlice";

import {deliveryTypeEnum} from "../../common/enums/deliveryTypeEnum";
import {TaskSegment} from "./segments/TaskSegment";
import {BundleSegment} from "./segments/BundleSegment";
import {Loader} from "../../common/components/Loader";

export const DashboardPageContainer = () => {

    const dispatch = useAppDispatch()

    const {options, settings, date, auth} = useAppSelector(state => state.root);
    const {quickBundle, activePage, totals, tasks, loading, limit} = useAppSelector(state => state.dashboard);
    const {updateTask} = useAppSelector(state => state.taskDetail)
    const activeTransportType = options.transportTypes.find((type: TypeInterface) => type.value === settings.transportType)
    const activeDeliveryType = options.deliveryTypes.find((type: TypeInterface) => type.value === settings.deliveryType)

    useEffect(() => {

        dispatch(loadTotals())

        if (activeDeliveryType?.value === deliveryTypeEnum.BUNDLE) {
            dispatch(loadBundles())
        } else {
            dispatch(loadTasks())
        }

    }, [settings, activePage, updateTask.updated, date, quickBundle.submitted, limit, auth])

    return (
     <>

         <DashboardHeader
          taskTotals={totals}
          activeTransportType={activeTransportType}
          options={options.transportTypes}
          setTransportType={(value: string) => {
              dispatch(setPage(0))
              dispatch(resetQuickBundle())
              dispatch(setSettings({
                  transportType: value
              }))
          }}
         />

         <DashboardTabs
          taskTotals={totals}
          activeTransportType={activeTransportType}
          activeDeliveryType={activeDeliveryType}
          options={options.deliveryTypes}
          setDeliveryType={(value: string) => {
              dispatch(setPage(0))
              dispatch(resetQuickBundle())
              dispatch(setSettings({
                  deliveryType: value
              }))
          }}
         />


         {!loading ?

          <div className={'o-container o-container--primary'}>

              {settings.deliveryType !== 'bundle' &&
              <>
                  <TaskSegment/>
                  <DashboardPagination
                      tasks={tasks}
                      pageClickAction={(value: any) => dispatch(setPage(value))}
                  />
              </>
              }

              {settings.deliveryType === 'bundle' &&
              <BundleSegment/>
              }

          </div>

          : <Loader/>

         }
     </>
    )


}